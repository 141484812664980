export const get9amNDaysFromNow = (days: number): Date => {
    // Get current date
    let currentDate: Date = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    currentDate.setHours(9, 0, 0, 0);
    return currentDate;
}

export const convertUTCToLocaleString = (utc: string) => {
    const utcDate = new Date(utc);
    return utcDate.toLocaleString();
}
