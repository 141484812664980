const RecordingUI = () => {
	return (
		<div className="flex items-center justify-center ">
			<div className="bg-white p-8 rounded-full">
				<div className="relative w-24 h-24 rounded-full bg-gray-400 animate-ping"></div>
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center flex-col">
					<div className="w-16 h-16 rounded-full bg-red-500 animate-pulse"></div>
					<div className="mt-4 text-center text-gray-700">Calling...</div>
				</div>
			</div>
		</div>
	);
};

export default RecordingUI;
