import Messages from "../pages/messages";

// settings
import Recordings from "pages/Settings/recordings";
import Charts from "../components/charts/index";
import NotFound from "../pages/NotFound";
import Profile from "../pages/Settings/profile";
import Security from "../pages/Settings/security";
import Pricing from "../pages/Settings/subscription";
// Auth
import Calling from "pages/Settings/calling";
import { PublicRoute } from "../hocs/PublicRoute";
import ForgotPassword from "../pages/Auth/forgot-password";
import Login from "../pages/Auth/login";
import ResetPassword from "../pages/Auth/reset-password";
import Signup from "../pages/Auth/signup";
import Payment from "../pages/payment";

// Add this import
import { Route } from "react-router-dom";

const routes = [
	{
		path: "/login",
		name: "Login",
		layout: "/",
		exact: true,
		component: (
			<PublicRoute>
				<Login />
			</PublicRoute>
		),
	},
	{
		path: "/signup",
		name: "Signup",
		layout: "/",
		exact: true,
		component: (
			<PublicRoute>
				<Signup />
			</PublicRoute>
		),
	},
	{
		path: "/forgot-password",
		name: "Forgot Password",
		layout: "/",
		exact: true,
		component: (
			<PublicRoute>
				<ForgotPassword />
			</PublicRoute>
		),
	},
	{
		path: "/api/reset-password/:token",
		name: "Reset Password",
		layout: "/",
		exact: true,
		component: (
			<PublicRoute>
				<ResetPassword />
			</PublicRoute>
		),
	},
	{
		path: "/",
		name: "Messages",
		layout: "/",
		exact: true,
		component: <Messages />,
	},
	{
		path: "/chat/:numberOrId",
		name: "Chat",
		layout: "/",
		exact: true,
		component: <Messages />,
	},
	{
		path: "/settings/profile",
		name: "Profile",
		layout: "/",
		exact: true,
		component: <Profile />,
	},
	{
		path: "/settings/subscription",
		name: "Subscription",
		layout: "/",
		exact: true,
		component: <Pricing />,
	},
	{
		path: "/settings/security",
		name: "Security",
		layout: "/",
		exact: true,
		component: <Security />,
	},
	{
		path: "/settings/recordings",
		name: "Recordings",
		layout: "/",
		exact: true,
		component: <Recordings />,
	},
	{
		path: "/payment",
		name: "Payment",
		layout: "/",
		exact: true,
		component: <Payment />,
	},
	{
		path: "*",
		name: "Not Found",
		layout: "/",
		exact: true,
		component: <NotFound />,
	},
	{
		path: "user-onboarding",
		name: "Calling Feature",
		layout: "/",
		exact: true,
		component: <Calling />,
	},
	{
		path: "/charts",
		name: "charts",
		layout: "/",
		exact: true,
		component: <Charts />,
	},
];

export { routes };
