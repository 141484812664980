import { Dialog, Transition } from "@headlessui/react";
import Loading from "components/common/Loading";
import Toast from "components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { getRecordings, selectUpdateRecordingRequest, updateRecording } from "store/reducers/recordingReducer";

const EditNameModal = ({ open, onClose, recording }: any) => {
	const [name, setName] = useState("");
	const { inProgress } = useAppSelector(selectUpdateRecordingRequest);
	const dispatch = useAppDispatch();

	useEffect(() => {
		setName(recording.name);
	}, [recording]);

	const handleSave = async () => {
		try {
			await dispatch(updateRecording({ id: recording.id, status: recording.status, name }));
			onClose();
			setName("");
			Toast.success("Success");
			dispatch(getRecordings());
		} catch {
			Toast.error(SOMETHING_WENT_WRONG_MESSAGE);
		}
	};

	return (
		<div className="">
			<Transition appear show={open || true} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-green/60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
									<h5>Edit Recording</h5>
									<input
										value={name}
										onChange={(e) => setName(e.target.value)}
										className="border-light-gray border rounded-md px-3 py-2 flex justify-between items-center w-full mt-6"
									/>

									<div className="mt-7 flex gap-2 justify-end pt-1">
										<button
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-[#6C757D] border-2 border-[#6C757D] hover:bg-white hover:text-[#6C757D] transition duration-500"
											onClick={onClose}
										>
											Cancel
										</button>
										<button
											onClick={handleSave}
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-secondary border-2 border-secondary hover:bg-white hover:text-secondary transition duration-500"
										>
											{inProgress ? <Loading isShowing={inProgress} /> : "Update"}
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default EditNameModal;
