import { configureStore } from "@reduxjs/toolkit";
import businessSlice from "./reducers/businessReducer";
import messagesSlice from "./reducers/messageReducer";
import recordingSlice from "./reducers/recordingReducer";
import subScriptionSlice from "./reducers/subScriptionReducer";
import userSlice from "./reducers/userReducer";

const store = configureStore({
	reducer: {
		messages: messagesSlice,
		business: businessSlice,
		user: userSlice,
		subscription: subScriptionSlice,
		recording: recordingSlice,
	},
});
declare global {
	interface Window {
		store: typeof store;
	}
}
window.store = store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;
