import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";

interface SocketContextType {
	socket: Socket | null;
}

const SocketContext = createContext<SocketContextType>({ socket: null });
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL as string;

export const useSocket = () => {
	return useContext(SocketContext);
};

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [socket, setSocket] = useState<Socket | null>(null);
	const ref = useRef<number>(0);

	console.log("socket provider", socket);
	useEffect(() => {
		console.log({ location, socket });
		if (!socket || !socket?.connected) {
			try {
				console.log("connecing", socket);
				const tokens = JSON.parse(localStorage.getItem("tokens") || "")?.access_token ?? {};
				const newSocket = io(SOCKET_SERVER_URL, {
					extraHeaders: {
						Authorization: `Bearer ${tokens}`,
					},
				});
				ref.current = 1;
				setSocket(newSocket);
			} catch {
				console.error("error");
			}
		}

		return () => {
			console.log("disconnecting");
			socket?.disconnect();
		};
	}, []);

	return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export default SocketContext;
