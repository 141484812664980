import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

type CommonTextInputProps = {
  controllerName: string;
  fieldType: string;
  controllerLabel: string;
  errMsg?: string;
  disabled?: boolean
};

const CommonTextInput: FC<CommonTextInputProps> = ({
  controllerName,
  fieldType,
  controllerLabel,
  errMsg,
  disabled
}) => {
  const { control } = useFormContext();

  return (
    <>
      {fieldType !== "mobile" && (
        <Controller
          name={controllerName}
          control={control}
          rules={{ required: errMsg ? errMsg : true }}
          render={({ field: { onChange, value, ...restField } }) => (
            <input
              type={fieldType === "password" ? "password" : fieldType}
              placeholder={controllerLabel}
              className="p-1 placeholder:text-light-gray/60 text-primary border border-light-gray rounded outline-none focus:border-black w-full"
              value={value ?? ''}
              onChange={onChange}
              disabled={disabled}
              {...restField}
            />
          )}
        />
      )}
      {fieldType === "mobile" && (
        <Controller
          name={controllerName}
          control={control}
          rules={{ required: errMsg ? errMsg : true }}
          render={({ field: { onChange, value, ...restField } }) => (
            <PhoneInput
              inputStyle={{ width: '100%', height: '100%', padding: '14px 14px 14px 48px' }}
              country={"us"}
              placeholder={controllerLabel}
              value={value ?? ''}
              onChange={onChange}
              disabled={disabled}
              {...restField}
            />
          )}
        />
      )}
    </>
  );
};
export default CommonTextInput;
