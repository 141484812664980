import { Link } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import CommonTextInput from "../../components/common/CommonTextInput";
import Loading from "../../components/common/Loading";
import useAuthHook from "../../hooks/useAuthHook";
import { UserLoginInput } from "../../interfaceTypes";
import { loginValidationScheme } from "../../utils/schema";

import MsgInput from "assets/images/msg-input.svg";
import RegisterBg from "assets/images/register-bg.jpg";

const Login = (): JSX.Element => {
	const { login, isLoading } = useAuthHook();

	const method = useForm({
		mode: "all",
		resolver: yupResolver(loginValidationScheme),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const { handleSubmit } = method;

	const handleLogin = async (data: UserLoginInput) => {
		await login(data);
	};

	document.title = "Skip the Call | Login";

	return (
		<div className="flex items-center xlg:h-screen xlg:min-h-0 min-h-screen">
			<div className="w-3/5 xlg:block hidden relative h-screen">
				<img src={RegisterBg} alt="registerBg" className="w-full h-full object-cover" />
				<div className="absolute top-[35%] left-1/2 translate-x-[-50%] w-full">
					<div className="2xl:w-[38%] w-[47%] w-[57%] mx-auto xl:mb-28 mb-14">
						<div className="relative">
							<img src={MsgInput} alt="msgInput" />
						</div>
						<div className="flex justify-end 2xl:-mt-7 relative mt-7">
							<img src={MsgInput} alt="msgInput" className="w-[147px] scale-x-[-1]" />
							<div className="xl:h-9 xl:w-9 h-6 w-6 rounded-full bg-gradient-to-r from-[#60B7C4] to-[#fff] absolute xl:-right-7 xl:bottom-1 -right-4 bottom-2 rotate-45" />
						</div>
					</div>
					<div className="text-center">
						<h1 className="xl:text-[45px] text-[40px] text-white font-extrabold mb-2 leading-[45px]">
							Welcome to skipthecall
						</h1>
						<p className="text-white font-semibold xl:text-base text-sm">
							Skip the phone call and get back to doing what you do best!
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center xlg:w-2/5 w-full p-4 h-full overflow-auto">
				<div className="max-w-[450px] mx-auto xl:w-[440px] xlg:w-auto md:w-[440px] w-full">
					<h2 className="text-primary xsm:text-4xl text-3xl font-bold leading-[44px] pb-2">Log in</h2>

					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleLogin)}>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">Email</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="email" controllerLabel="" fieldType="email" />
							</div>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">Password</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="password" controllerLabel="" fieldType="password" />
							</div>

							<div className="flex justify-between items-center pb-8">
								<div>
									<label className="container block relative cursor-pointer">
										<input type="checkbox" className="absolute top-0 left-0 h-0 w-0 cursor-pointer" />
										<span className="checkmark absolute top-0 left-0 h-4 w-4 bg-white border border-light-gray after:absolute after:hidden rounded-sm after:top-[0.5px] after:left-[4.5px] after:w-[5px] after:h-2.5 after:rotate-45" />
										<span className="text-dark-gray/80 text-sm block pl-6">Remember Me</span>
									</label>
								</div>
								<Link
									to="/forgot-password"
									className="text-secondary text-sm font-semibold hover:text-[#225363] transition duration-300"
								>
									Forgot Password?
								</Link>
							</div>
							<button
								type="submit"
								disabled={isLoading}
								className="bg-secondary border border-secondary rounded-md xsm:py-2 py-1.5 mb-3 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500 w-full"
							>
								{isLoading ? <Loading isShowing={isLoading} /> : `Login`}
							</button>
						</form>
					</FormProvider>
					<p className="text-dark-gray text-sm text-base text-center">
						Not Registered Yet?
						<Link to="/signup" className="text-secondary pl-2.5 hover:text-[#225363] transition duration-300">
							Create an account
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
