import { Switch } from "@headlessui/react";
import LoadingModal from "LoadingModal";
import AddRecordingModal from "components/AddRecordingModal";
import Breadcrumb from "components/Breadcrumb";
import DeletRecordModal from "components/DeletRecordModal";
import EditNameModal from "components/EditNameModal";
import RecordingViewerModal from "components/RecordingViewerModal";
import Toast from "components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import AuthPage from "hocs/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "hooks";
import useUserHook from "hooks/useUserHook";
import Sidebar from "layout/sidebar";
import moment from "moment";
import { useEffect, useState } from "react";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { Recording, getRecordings, selectRecordings, updateRecording } from "store/reducers/recordingReducer";

const Recordings = () => {
	const [showRecording, setShowRecording] = useState(false);
	const [remove, setRemove] = useState(false);
	const [viewRecording, setViewRecording] = useState(false);
	const dispatch = useAppDispatch();
	const recordings = useAppSelector(selectRecordings);
	const [loading, setLoading] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const { getCurrentUser } = useUserHook();

	document.title = "Skip the Call | Recordings";
	const [activeRecoring, setActiveRecording] = useState<Recording | null>(null);

	useEffect(() => {
		dispatch(getRecordings());
	}, []);

	const handleToggleSwitch = async (recording: Recording, checked: boolean) => {
		try {
			setLoading(true);
			await dispatch(
				updateRecording({ id: recording.id, status: checked ? "active" : "inactive", name: recording.name }),
			);
			setLoading(false);
			dispatch(getRecordings());
			await getCurrentUser();
		} catch (e) {
			setLoading(false);
			Toast.error(SOMETHING_WENT_WRONG_MESSAGE);
		}
	};

	const handleViewRecording = (recording: Recording) => {
		setActiveRecording(recording);
		setViewRecording(true);
	};

	function closeModal() {
		setShowRecording(false);
	}

	function openModal() {
		setShowRecording(true);
	}

	const handleDeleteClick = (recording: Recording) => {
		setRemove(true);
		setActiveRecording(recording);
	};

	const handleEditClick = (recording: Recording) => {
		console.log("handleclick");
		setOpenEdit(true);
		setActiveRecording(recording);
	};

	return (
		<div className="xlg:flex">
			<Sidebar />
			<LoadingModal open={loading} />
			<div className="xlg:ml-[75px] xlg:pt-0 pt-14 xlg:w-[calc(100%-75px)] w-full h-screen">
				<Breadcrumb />
				<div className="max-w-6xl mx-auto w-full xsm:p-6 p-4">
					<div className="mb-4 flex justify-end">
						<button
							onClick={openModal}
							className="group bg-secondary border border-secondary rounded-md py-1.5 px-4 mb-3 text-white text-base font-bold text-center hover:bg-white hover:text-secondary transition duration-500 flex items-center gap-1"
						>
							<span className="text-xl h-[30px]">+</span> Add new voice greeting
						</button>
					</div>
					<div className="bg-white shadow-xl border border-gray-300 rounded-lg recording-table">
						<div className="overflow-x-auto">
							<table className="w-full min-w-[942px]">
								<tr>
									<th className="py-4 px-6">No</th>
									<th className="py-4 px-6">Name</th>
									<th className="py-4 px-6">Status</th>
									<th className="py-4 px-6 w-64">Created</th>
									<th colSpan={2} className="py-4 px-6">
										Actions
									</th>
								</tr>
								{recordings.map((recording, index) => (
									<>
										<tr className="hover:bg-black/[0.04]">
											<td className="py-3 px-6 w-16 text-primary">{index + 1}</td>
											<td className="py-3 px-6 text-[#005DBA] transition duration-400 hover:text-[#023e7a] text-center">
												<button onClick={() => handleViewRecording(recording)}>{recording.name}</button>
											</td>
											<td className="py-3 px-6 text-center">
												<Switch
													checked={recording.status === "active"}
													onChange={(checked) => handleToggleSwitch(recording, checked)}
													className={`${
														recording.status === "active" ? "bg-[#2196F3]" : "bg-[#cccccc]"
													} relative inline-flex h-8 w-14 shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
												>
													<span className="sr-only">Use setting</span>
													<span
														aria-hidden="true"
														className={`${
															recording.status === "active" ? "translate-x-6" : "translate-x-0"
														} pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
													/>
												</Switch>
											</td>
											<td className="py-3 px-6 text-primary text-center">
												{moment(recording.createdAt).format("MMMM Do YYYY h:mm a")}
											</td>
											<td className="py-3 px-2 text-center w-[45px]" onClick={() => handleEditClick(recording)}>
												<button>
													<MdEdit className="w-6 h-6 text-[#005DBA]" />
												</button>
											</td>

											<td className="py-3 px-2 text-center w-[45px]" onClick={() => handleDeleteClick(recording)}>
												{recording.status !== "active" && (
													<button>
														<CiTrash className="w-6 h-6 text-[#DC3545]" />
													</button>
												)}
											</td>
										</tr>
									</>
								))}
							</table>
						</div>

						{/* <div className="p-3 flex items-center justify-end gap-2 pagination">
							<Pagination />
						</div> */}
					</div>
				</div>
			</div>
			<AddRecordingModal showRecording={showRecording} closeModal={closeModal} />
			{openEdit && <EditNameModal open={openEdit} onClose={() => setOpenEdit(false)} recording={activeRecoring} />}
			<DeletRecordModal remove={remove} setRemove={setRemove} id={activeRecoring?.id} />
			<RecordingViewerModal
				viewRecording={viewRecording}
				setViewRecording={setViewRecording}
				url={activeRecoring?.voiceMailUrl}
			/>
		</div>
	);
};

export default AuthPage(Recordings);
