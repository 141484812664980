import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { CreateUserSubscriptionAction, clearClientSecret } from "../store/actions/subScriptionAction";
import { SubScriptionState } from "../types/stateType";

const useSubScriptionHook = () => {
	const { isLoading, clientSecretKey } = useSelector<RootState, SubScriptionState>((state) => state.subscription);
	const dispatch = useDispatch();

	const createSubscription = (body: { priceId: string; noTrial?: boolean }) => {
		return dispatch<any>(CreateUserSubscriptionAction(body));
	};

	const clearSubscriptionClientSecretKey = () => {
		return dispatch<any>(clearClientSecret());
	};

	return {
		createSubscription,
		isLoading,
		clientSecretKey,
		clearSubscriptionClientSecretKey,
	};
};

export default useSubScriptionHook;
