import { yupResolver } from "@hookform/resolvers/yup";
import Breadcrumb from "components/Breadcrumb";
import CommonTextInput from "components/common/CommonTextInput";
import Loading from "components/common/Loading";
import AuthPage from "hocs/ProtectedRoute";
import useAuthHook from "hooks/useAuthHook";
import { updatePasswordInput } from "interfaceTypes";
import Sidebar from "layout/sidebar";
import { FormProvider, useForm } from "react-hook-form";
import { ResetPasswordAction } from "store/actions/userAction";
import { ResetPasswordSecurityPagedValidationSchema } from "utils/schema";

const Security = () => {
	const { isLoading, ResetPassword } = useAuthHook();

	const method = useForm({
		mode: "all",
		resolver: yupResolver(ResetPasswordSecurityPagedValidationSchema),
		defaultValues: {
			oldPassword: "",
			password: "",
		},
	});

	document.title = "Skip the Call | Security";

	const { handleSubmit } = method;

	const handleResetPassword = async (data: updatePasswordInput) => {
		const token = JSON.parse(localStorage.getItem("tokens") || "");
		const body = {
			password: data.password,
			token: token.access_token ?? "",
		};
		const { type } = await ResetPassword(body);
		if (type !== ResetPasswordAction.rejected.type) {
			//navigate("/login");
		}
	};

	return (
		<div className="xlg:flex">
			<Sidebar />
			<div className="xlg:ml-[75px] xlg:pt-0 pt-14 xlg:w-[calc(100%-75px)] w-full h-screen">
				<Breadcrumb />
				<div className="max-w-5xl mx-auto w-full xsm:p-6 p-4">
					<h1 className="text-dark-black text-2xl font-semibold mb-8">Change Password</h1>
					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleResetPassword)}>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">Old Password</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="oldPassword" controllerLabel="" fieldType="password" />
							</div>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">New Password</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="password" controllerLabel="" fieldType="password" />
							</div>
							<button
								type="submit"
								disabled={isLoading}
								className="bg-secondary border border-secondary rounded-md xsm:py-2 py-1.5 mb-3 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500 w-full"
							>
								{isLoading ? <Loading isShowing={isLoading} /> : `Reset Password`}
							</button>
						</form>
					</FormProvider>
				</div>
			</div>
		</div>
	);
};

export default AuthPage(Security);
