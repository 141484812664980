import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PricingBg from "assets/images/pricing-plan-bg.jpg";
import RegisterBg from "assets/images/register-bg.jpg";
import AuthPage from "hocs/ProtectedRoute";
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useState } from "react";
import { MdLogout } from "react-icons/md";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo-dark.png";
import PaymentForm from "../components/PaymentForm";
import Loading from "../components/common/Loading";
import useSubScriptionHook from "../hooks/subScriptionHook";
import API from "../utils/axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

function Payment() {
	const [plan, setPlan] = useState<any>(null);
	const { logout, user } = useAuthHook();
	const [couponCode, setCouponCode] = useState("");

	const handleLogout = async () => {
		await logout();
	};

	document.title = "Skip the Call | Payments";

	const { createSubscription, clientSecretKey, isLoading, clearSubscriptionClientSecretKey } = useSubScriptionHook();

	const handleCreateNewSubscription = async () => {
		console.log({ user });
		await createSubscription({
			priceId: plan?.stripePriceId.toString() ?? "",
			noTrial: !user?.account?.isTrial,
		});
		window.location.href = "/";
	};

	const options = {
		clientSecret: clientSecretKey,
	};

	useEffect(() => {
		API.get("subscriptions/plans").then((res) => {
			setPlan(res.data[0]);
		});
	}, []);

	return (
		<>
			<div className="flex xlg:flex-row flex-col items-center xlg:h-screen h-full">
				<div className="flex flex-col justify-center xlg:w-3/5 w-full h-full xlg:overflow-auto bg-green relative">
					<img src={RegisterBg} alt="registerBg" className="w-full h-full object-cover absolute top-0 left-0" />
					<div className="relative z-90 xlg:py-0 py-16">
						<div className="bg-white xsm:mx-16 mx-4 rounded-2xl py-12 relative overflow-hidden">
							<img
								src={PricingBg}
								alt="PricingBg"
								className="w-full h-full object-cover absolute top-0 left-0 rounded-2xl"
							/>
							<div className="pricing-circle-animation2 border border-green bg-transparent rounded-full" />
							<h1 className="text-center text-dark-black font-extrabold text-4xl mb-8 px-4 relative">
								Subscription setup
							</h1>
							<div className="max-w-4xl mx-auto w-full xlg:py-4 px-4">
								<div className="pricing-circle-animation border border-green bg-transparent rounded-full" />
								<div className="pricing-circle-animation1 border border-green bg-transparent rounded-full" />
								<div className="mx-auto max-w-xs shadow-2xl px-4 sm:py-12 py-6 bg-gradient-to-t from-green to-[#3e707f] rounded-xl text-center relative">
									<h4 className="text-3xl	font-extrabold text-white mb-7">Premium</h4>
									<h5 className="text-3xl font-bold text-white mb-3">
										{plan?.currency}{plan?.amount}
										<span className="text-white/80 text-sm">/mo</span>
									</h5>
									<ul className="flex flex-col items-center mt-7 text-base text-white mb-1 font-medium">
										<li>
											<b className="font-extrabold">Unlimited</b> contacts
										</li>
										<li><b className="font-extrabold">Dedicated</b> skipthecall phone number</li>
										<li>Live <b className="font-extrabold">support</b> when you need it</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="xlg:w-2/5 w-[100%] xlg:h-screen overflow-y-auto xlg:px-6 xlg:py-4 xlg:px-4 py-10 flex flex-col justify-center relative">
					<Link
						to={"#"}
						className={`absolute top-4 right-4 flex items-center px-4 py-2 text-sm border border-white/[0.08] rounded-md hover:bg-gray-100`}
						onClick={handleLogout}
					>
						<MdLogout className="w-5 h-5 mr-2" />
						Logout
					</Link>
					<div className="xl:w-[70%] xlg:w-[90%] md:w-[60%] sm:w-[70%] w-[90%] w-full mx-auto">
						{user?.account?.isTrial && (
							<div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
								<p className="font-bold">Start your free trial for 14 days. No credit card required.</p>
							</div>
						)}

						<div className="bg-[#efefef] border border-light-gray mt-4 rounded">
							<div className="flex justify-between px-4 py-3 border-b border-light-gray items-center">
								<img src={Logo} alt="logo" className="w-[120px]" />
								<div className="text-end">
									<h4 className="text-dark-black font-extrabold text-lg leading-4">
										{plan?.currency}{plan?.amount}
									</h4>
									<p className="text-dark-black/90 font-medium">
										/month
									</p>
								</div>
							</div>
						</div>
						<div className="mt-4">
							<label className="" htmlFor="coupon">Coupon code</label>
							<input
								type="text"
								id="coupon"
								placeholder="Coupon code (optional)"
								value={couponCode}
								onChange={(e) => setCouponCode(e.target.value)}
								className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-secondary"
							/>
						</div>
						{!clientSecretKey && (
							<button
								disabled={isLoading}
								onClick={handleCreateNewSubscription}
								className="bg-secondary border border-secondary mt-4 rounded-md xsm:py-2 py-1.5 mb-3 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500 w-full"
							>
								{isLoading ? <Loading isShowing={isLoading} /> : (
									user?.account?.isTrial ? 'Start trial' : 'Checkout'
								)}
							</button>
						)}
						{clientSecretKey && !isLoading && (
							<Elements stripe={stripePromise} options={options}>
								<PaymentForm />
							</Elements>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
export default AuthPage(Payment);
