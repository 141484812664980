import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EmailInput, SignUpUserInput, UserDetailsInput, UserLoginInput } from "../interfaceTypes";
import { RootState } from "../store";
import {
	ForgotPasswordAction,
	LoginAction,
	ResetPasswordAction,
	SignUpAction,
	UpdateUserAction,
	logoutAction,
} from "../store/actions/userAction";
import { UserState } from "../types/stateType";

const useAuthHook = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isLoading, isLoggedIn, user } = useSelector<RootState, UserState>((state) => state.user);

	const signUp = (body: SignUpUserInput) => {
		return dispatch<any>(SignUpAction(body));
	};

	const login = (body: UserLoginInput) => {
		return dispatch<any>(LoginAction(body));
	};

	const ForgotPassword = (body: EmailInput) => {
		return dispatch<any>(ForgotPasswordAction(body));
	};

	const ResetPassword = (body: { password: string; token: string }) => {
		return dispatch<any>(ResetPasswordAction(body));
	};

	const updateUser = (body: UserDetailsInput) => {
		return dispatch<any>(UpdateUserAction(body));
	};

	const logout = async () => {
		await dispatch<any>(logoutAction());
		navigate("/login");
	};

	return {
		user,
		isLoggedIn,
		signUp,
		login,
		logout,
		ForgotPassword,
		isLoading,
		ResetPassword,
		updateUser,
	};
};

export default useAuthHook;
