import { Dialog, Transition } from "@headlessui/react";
import Loading from "components/common/Loading";
import Toast from "components/common/Toast";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment } from "react";
import { deleteRecording, getRecordings, selectDeleteRecordingRequest } from "store/reducers/recordingReducer";

const DeletRecordModal = ({ remove, setRemove, id }: any) => {
	const dispatch = useAppDispatch();
	const { inProgress } = useAppSelector(selectDeleteRecordingRequest);

	const handleDelete = () => {
		dispatch(deleteRecording({ id })).then((res) => {
			if (res.payload.errors) {
				Toast.error(res.payload.errors.message);
			} else {
				Toast.success("Success");
				setRemove(false);
				dispatch(getRecordings());
			}
		});
	};

	return (
		<div className="">
			<Transition appear show={remove} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={() => setRemove(false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-green/60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
									<h1 className="text-gray-700 text-xl font-bold mb-6">Delete Confirmation</h1>
									<h4 className="text-primary text-lg font-semibold">Are you sure?</h4>
									<p className="text-primary text-sm">Do you really want to delete these records?</p>
									<div className="mt-8 flex gap-2 justify-end pt-1">
										<button
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-[#6C757D] border-2 border-[#6C757D] hover:bg-white hover:text-[#6C757D] transition duration-500"
											onClick={() => setRemove(false)}
										>
											Cancel
										</button>
										<button
											onClick={handleDelete}
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-[#DC3545] border-2 border-[#DC3545] hover:bg-white hover:text-[#DC3545] transition duration-500"
										>
											{inProgress ? <Loading isShowing={inProgress} /> : "Delete"}
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default DeletRecordModal;
