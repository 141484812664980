import { LineChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import API from "../../utils/axios";
export interface TemplateProps {
	oldCalls?: boolean;
	totalMessages?: boolean;
	doneChats?: boolean;
}
const styles = {
	statNumberContainer: {
		textAlign: 'center' as 'center',
		marginBottom: '10px', // Space between text and chart
	},
	largeStatNumber: {
		fontSize: '24px', // Large text size for the number
		margin: '0', // Remove default margin
	},
	smallStatText: {
		fontSize: '12px', // Smaller text size for the description
		margin: '0', // Remove default margin
	},
}
//TODO rename these api calls to better names
const Template = ({ oldCalls, totalMessages, doneChats }: TemplateProps) => {
	const [dataSet, setDataSet] = useState<any>();
	const [loading, setLoading] = useState(true); // Add loading state
	useEffect(() => {
		let api = "";
		let labelText = "";
		if (oldCalls) {
			api = "calls/stats";
			console.log(api);
			labelText = "Calls last 7 days";
		}
		else if (totalMessages) {
			api = "messages/stats";
			labelText = "Texts last 7 days";
		}
		else if (doneChats) {
			api = "chats/stats";
			labelText = "Resolved last 7 days";
		}
		API.get(api)
			.then((response) => {
				return response.data;
			})
			.then((data) => {
				const dataKeys = Object.keys(data);
				let total = 0;
				const formattedData = dataKeys.map((key) => {
					total += data[key];
					return {
						day: Number(key),
						value: data[key],
					}
				});
				setDataSet({ series: formattedData, total: total, label: labelText });
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const valueFormatter = (value: number | null) => `${value}`;
	const chartSetting = {
		// yAxis: [{ label: oldCalls ? "Calls" : totalMessages ? "Messages" : "Done Chats" }],
		width: 100,
		height: 30,
	};

	return (
		<div>
			{loading ? (
				<p>Loading...</p>
			) : (
				<>
					<div style={styles.statNumberContainer}>
						<h1 style={styles.largeStatNumber}>{dataSet.total}</h1>
						<p style={styles.smallStatText}>{dataSet.label}</p>
					</div>
					<LineChart
						dataset={dataSet.series}
						leftAxis={null}
						bottomAxis={null}
						// xAxis={[{ scaleType: "band", dataKey: "day", label: "Day" }]}
						series={[{ dataKey: "value", showMark: false, valueFormatter }]}
						// layout="vertical"
						{...chartSetting}
						margin={{ left: 2, right: 2, top: 2, bottom: 2 }}

					// slotProps={{
					// 	bar: {
					// 		clipPath: `inset(0px round 10px 10px 0px 0px)`,
					// 	},
					// }}
					/>
				</>
			)}
		</div>
	);
};

export const OldCalls = () => {
	const props = { oldCalls: true };
	return <Template {...props} />;
};
export const DoneChats = () => {
	const props = { doneChats: true };
	return <Template {...props} />;
};
export const TotalMessages = () => {
	const props = { totalMessages: true };
	return <Template {...props} />;
};
export const MessagesChart = () => {
	const data = API.get("messages/total-messages")
		.then((response) => {
			return response.data;
		})
		.then()


}
