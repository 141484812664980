import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateCallsForThread } from "store/reducers/messageReducer";
import { IState } from "types/stateType";
import API from "../../utils/axios";
import { CallModal } from "./CallModal";

interface IContactInfo {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Calls: React.FC<IContactInfo> = ({ isOpen, setIsOpen }) => {
	const { currentThread } = useSelector((state: IState) => state.messages);
	const [show, setShow] = useState(false);
	const [newNote, setNewNote] = useState("");
	const [showNewNote, setShowNewNote] = useState(false);

	const dispatch = useDispatch();

	const getCalls = () => {
		if (currentThread?.id && !currentThread.calls) {
			API.get(`calls/${currentThread?.id}?page=${1}&pageSize=${Number.MAX_SAFE_INTEGER}`).then((res) => {
				dispatch(updateCallsForThread({ clientId: currentThread.id, calls: res.data.data }));
			});
		}
	};

	useEffect(() => {
		getCalls();
	}, [currentThread]);

	const handleCall = () => {
		setShow(true);
	};

	const handleDelete = (call: any) => {
		 if (call && call.id) {
        API.delete(`calls/${call.id}`)
           .then(() => {
                dispatch(updateCallsForThread({
                    clientId: currentThread?.id,
                    calls: currentThread?.calls.filter((c: any) => c.id !== call.id),
                }));
            })
            .catch((error) => {
                console.error("Error deleting call:", error);
            });
    }
	};

	return (
		<div
			className={`${
				isOpen ? "translate-x-0" : "translate-x-full"
			} z-[99] fixed right-0 sm:w-[380px] w-[320px] h-screen shadow-1xl bg-white transition duration-500`}
		>
			<div className="flex justify-end items-center border-b border-light-gray px-6 py-[26px] ">
				<button onClick={() => setIsOpen(false)}>
					<MdClose className="h-6 w-6 text-slate" />
				</button>
			</div>
			<div className="p-4 h-[calc(100%-77px)] overflow-y-auto">
				<div className="mb-10">
					<div className="flex justify-between  mb-2 flex-col">
						<label className="text-dark-gray text-sm font-semibold w-full">Calls Logs</label>
						{currentThread?.calls === null && <p>Fetching Call logs</p>}
						{currentThread?.calls?.length === 0 && <p>No Call logs</p>}
						{currentThread?.calls?.map((call: any) => (
							<>
								{call.recordingSid && call.recordingUrl && (
									<div className="flex items-center mb-4">
										<audio controls className="mr-2">
											<source src={call.recordingUrl} type="audio/mpeg" />
											Your browser does not support the audio element.
										</audio>
										{/* <p className="text-sm">{moment(call.createdAt).format()}</p> */}
										<MdClose onClick={() => handleDelete(call)} />
									</div>
								)}
							</>
						))}
						<button className="border-gray-400 border-r-1 rounded-md bg-green text-white h-8" onClick={handleCall}>
							Call {currentThread?.name}
						</button>
						{showNewNote && (
							<>
								<textarea
									placeholder="Add note here"
									value={newNote}
									onChange={(e) => setNewNote(e.target.value)}
									className="p-2.5 mt-4 placeholder:text-light-gray/60 text-primary border border-light-gray rounded-lg outline-none focus:border-black w-full"
								/>

								<button className={`bg-[#28a745] px-2.5 rounded-2xl text-sm text-white font-bold py-0.5 mt-2`}>
									Save
								</button>
							</>
						)}
					</div>
				</div>
				{show && <CallModal open={show} onClose={setShow} clientId={currentThread?.id} />}
			</div>
		</div>
	);
};

export default Calls;
