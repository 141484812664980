import { createAsyncThunk, createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import Toast from "components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import { RootState } from "store";
import { Request, RequestInitialState } from "types/stateType";
import API from "../../utils/axios";

export type IRecordings = {
	recordings: Recording[];
	requests: {
		createRecording: Request;
		updateRecording: Request;
		getRecordings: Request;
		deleteRecording: Request;
	};
};

type RecorgingStatus = "active" | "inactive";

export type Recording = {
	id: string;
	name: string;
	status: RecorgingStatus;
	createdAt: string;
	voiceMailUrl: string;
};

type VoiceMailProps = {
	blob: Blob;
	name: string;
	status: RecorgingStatus;
};

export const createRecording = createAsyncThunk(
	"recordings/createRecording",
	async ({ blob, name, status }: VoiceMailProps, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await API.postForm("voiceMail", { voiceMail: blob, name, status });
			if (response.status !== 201) {
				throw Error(response.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return fulfillWithValue(response.data.data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return rejectWithValue(new Error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE));
		}
	},
);

export const updateRecording = createAsyncThunk(
	"recordings/updateRecording",
	async ({ name, status, id }: Partial<VoiceMailProps> & { id: string }, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await API.patch(`voiceMail/${id}`, { name, status });
			if (response.status !== 201) {
				throw Error(response.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return fulfillWithValue(response.data.data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return rejectWithValue(new Error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE));
		}
	},
);

export const getRecordings = createAsyncThunk(
	"recordings/getRecordings",
	async (_, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await API.get("voiceMail");
			if (response.status !== 200) {
				throw Error(response.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return fulfillWithValue(response.data.data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return rejectWithValue(new Error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE));
		}
	},
);

export const deleteRecording = createAsyncThunk(
	"recordings/deleteRecording",
	async ({ id }: { id: string }, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await API.delete(`voiceMail/${id}`);
			if (response.status !== 200) {
				throw Error(response.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return fulfillWithValue(response.data.data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return rejectWithValue(new Error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE));
		}
	},
);

export const recordingsSlice = createSlice<IRecordings, SliceCaseReducers<IRecordings>, string>({
	name: "recordings",
	initialState: {
		recordings: [],
		requests: {
			createRecording: RequestInitialState,
			updateRecording: RequestInitialState,
			getRecordings: RequestInitialState,
			deleteRecording: RequestInitialState,
		},
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(createRecording.pending, (state) => {
			state.requests.createRecording.inProgress = true;
		});
		builder.addCase(createRecording.rejected, (state, action) => {
			state.requests.createRecording.error = action.error;
			state.requests.createRecording.inProgress = false;
		});
		builder.addCase(createRecording.fulfilled, (state) => {
			state.requests.createRecording.inProgress = false;
			state.requests.createRecording.success = true;
		});
		builder.addCase(getRecordings.pending, (state) => {
			state.requests.getRecordings.inProgress = true;
		});
		builder.addCase(getRecordings.rejected, (state, action) => {
			state.requests.getRecordings.error = action.error;
			state.requests.getRecordings.inProgress = false;
		});
		builder.addCase(getRecordings.fulfilled, (state, action) => {
			state.requests.getRecordings.inProgress = false;
			state.requests.getRecordings.success = true;
			state.recordings = action.payload;
		});
		builder.addCase(deleteRecording.pending, (state) => {
			state.requests.deleteRecording.inProgress = true;
		});
		builder.addCase(deleteRecording.rejected, (state, action) => {
			state.requests.deleteRecording.error = action.error;
			state.requests.deleteRecording.inProgress = false;
		});
		builder.addCase(deleteRecording.fulfilled, (state, action) => {
			state.requests.deleteRecording.inProgress = false;
			state.requests.deleteRecording.success = true;
		});
		builder.addCase(updateRecording.pending, (state) => {
			state.requests.updateRecording.inProgress = true;
		});
		builder.addCase(updateRecording.rejected, (state, action) => {
			state.requests.updateRecording.error = action.error;
			state.requests.updateRecording.inProgress = false;
		});
		builder.addCase(updateRecording.fulfilled, (state, action) => {
			state.requests.updateRecording.inProgress = false;
			state.requests.updateRecording.success = true;
		});
	},
});

export const selectRecordings = (state: RootState) => state.recording.recordings;
export const selectCreateRecordingRequest = (state: RootState) => state.recording.requests.createRecording;
export const selectDeleteRecordingRequest = (state: RootState) => state.recording.requests.deleteRecording;
export const selectUpdateRecordingRequest = (state: RootState) => state.recording.requests.updateRecording;

// export const { setIsToast, setCurrentThread, addNewMessage, updateThread, removeThread, setChatHookRef, setUserOnlineStatus, updateCallsForThread } = messagesSlice.actions;

export default recordingsSlice.reducer;
