import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ReminderModal = ({ show, closeModal, onSave }: any) => {

    const [date, setDate] = useState('');

    const handleDateChange = (dateString: string) => {
        const date = new Date(dateString);
        date.setHours(9, 0, 0, 0);
        setDate(date.toISOString())
    }
    return (
        <div className="">
            <Transition appear show={show} as={Fragment}>
                <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-green/60" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold text-primary"
                                    >
                                        Custom Reminder
                                    </Dialog.Title>
                                    <div className="flex justify-between items-center my-7">
                                        <label className="text-dark-gray text-base font-semibold w-20 w-full">
                                            Date
                                        </label>
                                        <input
                                            type="date"
                                            placeholder="date"
                                            onChange={(e) => handleDateChange(e.target.value)}
                                            className="p-2.5 placeholder:text-light-gray/60 text-primary border border-light-gray rounded outline-none focus:border-black w-full"
                                        />
                                    </div>
                                    <div className="mt-4 flex gap-2 justify-end pt-1">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-dark-gray px-4 py-1.5 font-semibold text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => onSave(date)}
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-secondary px-4 py-1.5 font-semibold text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default ReminderModal;
