export const businessDetails = {
  id: "id",
  business_name: "business name",
  email: "johnDoe@gmail.com",
  user_name: "user name",
  reffer_link: "reffer_link",
};





