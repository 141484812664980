import Toast from "components/common/Toast";
import React, { useEffect } from "react";
import { MdBlock, MdCall, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsWidthMessage } from "store/actions/messageAction";
import { setCurrentThread, updateThread } from "store/reducers/messageReducer";
import { IState } from "types/stateType";

import API from "../../utils/axios";

interface IContactInfo {
	profileInfo: boolean;
	setProfileInfo: React.Dispatch<React.SetStateAction<boolean>>;
}
const ContactInfo: React.FC<IContactInfo> = ({ profileInfo, setProfileInfo }) => {
	const { currentThread: thread, threads } = useSelector((state: IState) => state.messages);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!thread) {
			dispatch<any>(getUserDetailsWidthMessage({ id: threads?.[0]?.id }));
		}
	}, [threads?.length, !thread]);

	const handleblockUser = () => {
		API.post("blocked", { clientId: thread?.id }).then(() => {
			Toast.success("Chat blocked");
			const updatedThread = {
				...thread,
				chat: { ...thread?.chat, chatResolution: { ...thread?.chat?.chatResolution, type: "spam" } },
			};
			dispatch(setCurrentThread(null));
			dispatch(updateThread(updatedThread));
			setProfileInfo(false);
		});
	};

	return (
		<div
			className={`${
				profileInfo ? "translate-x-0" : "translate-x-full"
			} z-[99] fixed right-0 sm:w-[380px] w-[320px] h-screen shadow-1xl bg-white transition duration-500`}
		>
			<div className="flex justify-between items-center border-b border-light-gray p-6 ">
				<h6 className="text-lg font-semibold text-slate">Contact info</h6>
				<button onClick={() => setProfileInfo(false)}>
					<MdClose className="h-6 w-6 text-slate" />
				</button>
			</div>
			<div className="p-4">
				{/* <img
          src={thread?.userSender?.avatar}
          alt="profile"
          className="rounded-full mx-auto my-8"
        /> */}

				<h6 className="font-semibold text-dark-black text-center text-xl">{thread?.name}</h6>

				<div className="grid grid-cols-2 gap-4 pb-7 border-b border-[#DDDDDD] p-5">
					<button className="flex items-center bg-secondary rounded py-2.5 gap-2 text-white justify-center font-semibold text-lg">
						<MdCall className="h-5 w-5" />
						Call
					</button>
					<button
						className="flex items-center bg-[#BB2124] rounded py-2.5 gap-2 text-white justify-center font-semibold text-lg"
						onClick={handleblockUser}
					>
						<MdBlock className="h-6 w-6" />
						Block
					</button>
				</div>
				<p className="font-bold text-base text-dark-gray mt-6">
					Phone: <span className="text-slate font-semibold pl-3">{thread?.number}</span>
				</p>
			</div>
		</div>
	);
};

export default ContactInfo;
