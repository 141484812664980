import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const RecordingViewerModal = ({ viewRecording, setViewRecording, url }: any) => {
	return (
		<div className="">
			<Transition appear show={viewRecording} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={() => setViewRecording(false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-green/60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
									<h1 className="text-gray-700 text-xl font-bold mb-6">Audio Player</h1>
									<audio controls className="w-full">
										<source type={"audio/mp3"} src={url} />
										Your browser does not support the audio element.
									</audio>

									<div className="mt-8 flex gap-2 justify-end pt-1">
										<button
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-[#6C757D] border-2 border-[#6C757D] hover:bg-white hover:text-[#6C757D] transition duration-500"
											onClick={() => setViewRecording(false)}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default RecordingViewerModal;
