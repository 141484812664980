import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  editBusinessAction,
  fetchBusinessAction,
} from "store/actions/businessAction";

export type BusinessDetails = {
  id: string;
  business_name: string;
  email: string;
  user_name: string;
  reffer_link: string;
};

export type IBusinessState = {
  business: BusinessDetails[];
  isLoading: boolean;
  error: boolean;
  message: string;
};

export const messagesSlice = createSlice<
  IBusinessState,
  SliceCaseReducers<IBusinessState>,
  string
>({
  name: "messages",
  initialState: {
    business: [],
    isLoading: false,
    error: false,
    message: "",
  },
  reducers: {
    setIsToast(
      state,
      { payload }: { payload: { isLoading: boolean; message?: string } }
    ) {
      state.isLoading = payload.isLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessAction.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(
      fetchBusinessAction.fulfilled,
      (state, { payload }: any) => {
        state.business = payload;
        state.isLoading = false;
        state.error = false;
      }
    );
    builder.addCase(fetchBusinessAction.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      state.error = true;
      if (payload) state.message = payload?.message;
    });
    builder.addCase(editBusinessAction.pending, (state: any) => {
      state.error = false;
    });
    builder.addCase(editBusinessAction.fulfilled, (state, { payload }: any) => {
      state.business = payload;
      state.isLoading = false;
      state.error = false;
    });
    builder.addCase(editBusinessAction.rejected, (state, { payload }: any) => {
      state.isLoading = false;
      state.error = true;
      if (payload) state.message = payload?.message;
    });
  },
});
export const { setIsToast } = messagesSlice.actions;

export default messagesSlice.reducer;
