import { useSocket } from "Providers/SocketProvider";
import Toast from "components/common/Toast";
import useUserHook from "hooks/useUserHook";
import { CommonLayout } from "layout/CommonLayout";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";
import useAuth from "../hooks/useAuthHook";

const RedirectComponent: React.FC<{ path: string }> = ({ path }) => {
	useEffect(() => {
		window.location.href = path;
	}, []);

	return <></>;
};

function AuthPage(Component: any) {
	return function HOC(props: any) {
		const location = useLocation();
		const { isLoggedIn, user } = useAuth();
		const { getCurrentUser } = useUserHook();
		const { socket } = useSocket();

		useEffect(() => {
			getCurrentUser();
		}, []);

		console.log(`User loaded!`, user)
		const plan = getPaymentPlan(user);
		console.log(`Current plan status: ${plan}`)
		const isActive = plan === PaymentStatus.ACTIVE;
		const isPastDue = plan === PaymentStatus.PAST_DUE;
		const isOnTrial = plan === PaymentStatus.TRIAL;
		const isInComplete = plan === PaymentStatus.INCOMPLETE;

		useEffect(() => {
			socket?.on("payment-status", async (data: any) => {
				console.log("payment success", data);
				if (data.status === "active") {
					// setLoading(false);
					Toast.success("Payment successful!");
					// await getCurrentUser();
				} else if (data.status === "failed") {
					Toast.error(data.message as string);
					// setLoading(false);
					// elements.getElement(PaymentElement)?.clear();
				} else if (data.status === "trialing") {
					Toast.error(data.message as string);
					getCurrentUser();
				}
			});
		}, [socket]);

		// console.log(`isLoggedIn: ${isLoggedIn}`)
		// console.log(`isActive: ${isActive}`)
		// console.log(`isOnTrial: ${isOnTrial}`)
		// console.log(`isPastDue: ${isPastDue}`)
		// console.log(`isInComplete: ${isInComplete}`)

		if (isLoggedIn) {
			if (!isActive && !isOnTrial) {
				if (isPastDue || isInComplete) {
					if (location.pathname === "/settings/subscription") {
						return (
							<CommonLayout>
								<Component {...props} />
							</CommonLayout>
						);
					} else {
						return <Navigate to="/settings/subscription" />;
					}
				} else if (location.pathname === "/payment") {
					return (
						<CommonLayout>
							<Component {...props} />
						</CommonLayout>
					);
				} else {
					return <RedirectComponent path="/payment" />;
				}
			} else if (plan) {
				if (location.pathname === "/payment") {
					return <RedirectComponent path="/" />;
				} else {
					if (user?.isComplete) {
						if (location.pathname === "/user-onboarding") {
							return <RedirectComponent path="/" />;
						} else
							return (
								<CommonLayout>
									<Component {...props} />
								</CommonLayout>
							);
					} else {
						if (location.pathname === "/user-onboarding") {
							return (
								<CommonLayout>
									<Component {...props} />
								</CommonLayout>
							);
						} else return <RedirectComponent path="/user-onboarding" />;
					}
				}
			} else {
				console.log("in else");
				return <RedirectComponent path="/payment" />;
			}
		} else {
			console.log("Redirecting to /login");
			return <Navigate to={"/login"} />;
		}
	};
}

export default AuthPage;
