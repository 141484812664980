import useChat from "hooks/useChat";
import React, { ReactNode, createContext, useContext } from "react";

interface ChatMethodsContextType {
	chatMethods: any;
}

const ChatMethodsContext = createContext<ChatMethodsContextType>({ chatMethods: null });

export const useChatMethods = () => {
	return useContext(ChatMethodsContext);
};

export const ChatMethodsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const chatMethods = useChat({});
	return <ChatMethodsContext.Provider value={{ chatMethods }}>{children}</ChatMethodsContext.Provider>;
};

export default ChatMethodsContext;
