import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";

function AppRouter() {
	return (
		<Routes>
			{routes.map((prop, i) => (
				<Route path={prop.path} element={prop.component} key={i} />
			))}
		</Routes>
	);
}
export default AppRouter;
