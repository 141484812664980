import Calls from "components/Call";
import ChatNotes from "components/ChatNotes";
import ContactInfo from "components/ContactInfo";
import Message from "components/Message";
import MessageList from "components/MessageList";
import AuthPage from "hocs/ProtectedRoute";
import useAuthHook from "hooks/useAuthHook";
import useUserHook from "hooks/useUserHook";
import Layout from "layout";
import { useEffect, useState } from "react";

const Messages = () => {
	const [open, setOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isCallsOpen, setIsCallsOpen] = useState(false);
	const [profileInfo, setProfileInfo] = useState(false);
	const { user } = useAuthHook();
	const { getCurrentUser } = useUserHook();

	useEffect(() => {
		getCurrentUser();
	}, []);

	return (
		<Layout>
			<MessageList open={true} setOpen={setOpen} />
			<Message
				open={open}
				setOpen={setOpen}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				profileInfo={profileInfo}
				setProfileInfo={setProfileInfo}
				isCallsOpen={isCallsOpen}
				setIsCallsOpen={setIsCallsOpen}
			/>

			<ContactInfo profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
			<ChatNotes
				isOpen={isOpen}
				setIsOpen={(a) => {
					console.log(a);
					setIsOpen(a);
					setProfileInfo(false);
					setIsCallsOpen(false);
				}}
			/>
			<Calls
				isOpen={isCallsOpen}
				setIsOpen={(a) => {
					console.log(a);
					setIsCallsOpen(a);
					setProfileInfo(false);
					setIsOpen(false);
				}}
			/>
		</Layout>
	);
};

export default AuthPage(Messages);
