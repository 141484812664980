import Toast from "components/common/Toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentThread, updateThread } from "store/reducers/messageReducer";
import { IState } from "types/stateType";
import API from "../../utils/axios";

export const Note = ({ note }: any) => {
	const { currentThread } = useSelector((state: IState) => state.messages);
	const [updatedNote, setUpdatedNote] = useState(note);
	const [value, setValue] = useState<string | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		setUpdatedNote(note);
	}, [note]);

	const handleSave = (e: any) => {
		setValue(null);
		updateNote();
	};

	const updateNote = () => {
		API.patch(`/clientNotes/${note.id}`, { text: updatedNote.text, clientId: currentThread?.id }).then(() => {
			Toast.success("Note updated");
		});
	};

	const handleDelete = () => {
		API.delete(`/clientNotes/${note.id}`).then(() => {
			Toast.success("Note deleted");
			const updatedThread = {
				...currentThread,
				clientNote: currentThread?.clientNote.filter((cNote) => cNote.id !== note.id),
			};
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
		});
	};

	return (
		<>
			<textarea
				placeholder="Add note here"
				value={updatedNote.text}
				onChange={(e) => setUpdatedNote(e.target.value)}
				disabled={!value}
				maxLength={80}
				className="p-2.5 mt-4 placeholder:text-light-gray/60 text-primary border border-light-gray rounded-lg outline-none focus:border-black w-full"
			/>
			<div className="flex flex-row gap-1">
				<button
					onClick={() => setValue("save")}
					className={`bg-secondary px-2.5 rounded-2xl text-sm text-white font-bold py-0.5 ${
						!value || value === "edit" ? "block" : "hidden"
					}`}
				>
					Edit
				</button>
				<button
					onClick={handleDelete}
					className={`bg-secondary px-2.5 rounded-2xl text-sm text-white font-bold py-0.5 ${
						!value || value === "edit" ? "block" : "hidden"
					}`}
				>
					Delete
				</button>
			</div>
			<button
				onClick={handleSave}
				className={`bg-[#28a745] px-2.5 rounded-2xl text-sm text-white font-bold py-0.5 ${
					value === "save" ? "block" : "hidden"
				}`}
			>
				Save
			</button>
		</>
	);
};
