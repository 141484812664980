import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
	addThread,
	blockThread,
	fetchSingleThread,
	fetchThreadsAction,
	getUserDetailsWidthMessage,
	seenMessageAction,
	sendMessage,
} from "store/actions/messageAction";

export type Chat = {
	chatResolution: [{ id: string; slug: string; type: string }];
	id: string;
	lastMessage: string;
	snoozeReminder: [any];//TODO define this type
	status: string;
	unreadMsgs: number;
};

export type Thread = {
	id: string;
	name: string;
	number: string;
	clientNote: any[];
	chat: Chat;
	messages: Message[];
	blocked: [];
	favorite: boolean;
	isOnline?: boolean;
	calls: [];
};

export type Message = {
	id: string | null;
	senderType: string;
	content: string;
	senderId: string;
	images: any[];
	sentAt: string;
	createdAt: string;
	type: string;
	audios?: any[];
};

export type IMessagesState = {
	threads: Thread[];
	filterThread: Thread[];
	noThreads: null | boolean;
	activeThreads: Thread[];
	doneThreads: Thread[];
	spamThreads: Thread[];
	currentThread: Thread | null;
	isLoading: boolean;
	fetchThreadLoading: boolean | null;
	fetchAllClientsLoading: boolean | null;
	error: boolean;
	message: string;
};

export const messagesSlice = createSlice<IMessagesState, SliceCaseReducers<IMessagesState>, string>({
	name: "messages",
	initialState: {
		threads: [],
		noThreads: null,
		filterThread: [],
		activeThreads: [],
		doneThreads: [],
		spamThreads: [],
		currentThread: null,
		isLoading: false,
		fetchThreadLoading: null,
		fetchAllClientsLoading: null,
		error: false,
		message: "",
	},
	reducers: {
		setIsToast(state, { payload }: { payload: { isLoading: boolean; message?: string } }) {
			state.isLoading = payload.isLoading;
		},
		setUserOnlineStatus(state, { payload }: { payload: { ids: string[] } }) {
			state.threads?.map((thread, index) => {
				state.threads[index].isOnline = payload.ids.includes(thread.id);
			});
		},
		setCurrentThread: (state, action) => {
			state.currentThread = action.payload;
		},
		updateThread: (state, action) => {
			const index = state.threads.findIndex((thread) => thread.id === action.payload.id);
			state.threads[index] = action.payload;
		},
		updateCallsForThread: (state, action) => {
			const { clientId, calls } = action.payload;
			const index = state.threads.findIndex((thread) => thread.id === clientId);
			state.threads[index].calls = calls;
			if (state.currentThread?.id === clientId && state.currentThread) {
				state.currentThread.calls = calls;
			}
		},
		removeChat: (state, action) => {
			const index = state.threads.findIndex((thread) => thread.id === action.payload);
			state.threads[index] = { ...state.threads[index], chat: null as any, messages: [] };
		},
		addNewMessage: (state, action) => {
			function moveItemToFrontWithFavorite(array: any[], index: number) {
				if (index < 0 || index >= array.length) {
					// Index out of bounds
					return array;
				}

				const item = array.splice(index, 1)[0]; // Remove item at given index
				let favoriteItems = [];
				let nonFavoriteItems = [];

				array.forEach((element) => {
					if (element.favorite) {
						favoriteItems.push(element);
					} else {
						nonFavoriteItems.push(element);
					}
				});

				if (item.favorite) {
					// If the item itself is favorite, add it to the beginning
					favoriteItems.unshift(item);
				} else {
					nonFavoriteItems.unshift(item);
				}

				// Concatenate favorite items and non-favorite items
				array = favoriteItems.concat(nonFavoriteItems);
				return array;
			}

			const { message, clientId } = action.payload;
			const threadIndex = state.threads?.findIndex((thread) => thread.id === clientId);
			if (threadIndex !== undefined && threadIndex !== -1) {
				if (state?.threads[threadIndex]?.messages)
					state.threads[threadIndex].messages = [...state.threads[threadIndex].messages, message];
				state.threads[threadIndex].chat = { ...state.threads[threadIndex].chat, lastMessage: message.content };
				if (message.senderType === "client") {
					if (state.currentThread?.id === clientId) {
						state.threads[threadIndex].chat.unreadMsgs = 0;
					} else {
						state.threads[threadIndex].chat.unreadMsgs = (state.threads[threadIndex].chat.unreadMsgs || 0) + 1;
					}
				}
			} else {
				const { client, chatId } = action.payload;
				const thread = {
					id: clientId,
					name: client.name,
					number: client.number,
					clientNote: [],
					blocked: [],
					chat: {
						id: chatId,
						lastMessage: message.content,
						chatResolution: null,
						unreadMsgs: 1,
						snoozeReminder: null,
						status: "Active",
						favorite: false,
					},
					messages: [message],
				};
				console.log({ thread });

				if (state.threads?.length) {
					//@ts-ignore
					state.threads = [thread, ...state.threads];
				} else {
					//@ts-ignore
					state.threads = [thread];
				}
			}
			if (state.currentThread && state.currentThread?.id === clientId) {
				state.currentThread = { ...state.currentThread, messages: [...state.currentThread?.messages, message] };
			}
			state.threads = moveItemToFrontWithFavorite(state.threads, threadIndex);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchThreadsAction.pending, (state: IMessagesState) => {
			state.fetchAllClientsLoading = true;
			state.error = false;
		});
		builder.addCase(fetchThreadsAction.fulfilled, (state: IMessagesState, { payload }) => {
			state.threads = payload?.map((clients: any) => ({ ...clients, messages: null })) || [];
			// @ts-ignore
			state.threads = payload
				?.map((clients: any) => ({ ...clients, messages: null }))
				.sort((a: any, b: any) => {
					if (a.favorite && !b.favorite) {
						return -1;
					} else if (!a.favorite && b.favorite) {
						return 1;
					} else {
						// If both or neither are favourite, sort by the specified argument
						// @ts-ignore
						return new Date(b["sortArg"]) - new Date(a["sortArg"]);
					}
				});
			state.fetchAllClientsLoading = false;
			state.error = false;
			if (payload) {
				const activeClients = payload?.filter(
					(thread: Thread) =>
						thread.chat &&
						!thread?.chat?.chatResolution &&
						thread?.blocked?.length === 0 &&
						(thread.chat?.snoozeReminder?.[0] === null ||
							thread.chat?.snoozeReminder?.[0]?.snoozeUntil < new Date().toISOString()),
				);
				state.currentThread = activeClients[0];
			}
			state.noThreads = !payload;
		});
		builder.addCase(fetchThreadsAction.rejected, (state: IMessagesState) => {
			state.fetchAllClientsLoading = false;
			state.error = true;
		});
		builder.addCase(seenMessageAction.pending, (state: IMessagesState, action) => {
			state.isLoading = true;
			state.error = false;
			if (action.meta.arg.id) {
				const threadIndex = state.threads.findIndex((thread) => thread.chat?.id === action.meta.arg.id);
				console.log({ threadIndex });
				if (threadIndex !== -1) {
					state.threads[threadIndex].chat.unreadMsgs = 0;
				}
				if (state.currentThread) {
					state.currentThread.chat.unreadMsgs = 0;
				}
			}
		});
		builder.addCase(seenMessageAction.fulfilled, (state: IMessagesState, { payload, meta }) => {
			const newstate = state.threads.map((obj) => {
				if (obj.id === payload) {
					return { ...obj, unSeenMessageCount: 0, messages: obj.messages };
				}
				return obj;
			});
			if (state.threads) {
				state.threads = newstate;
			}
			state.isLoading = false;
			state.error = false;
			const threadIndex = state.threads.findIndex((thread) => thread.chat?.id === meta.arg.id);
			console.log({ threadIndex });
			if (threadIndex !== -1) {
				state.threads[threadIndex].chat.unreadMsgs = 0;
			}
			if (state.currentThread) {
				state.currentThread.chat.unreadMsgs = 0;
			}
		});
		builder.addCase(seenMessageAction.rejected, (state: IMessagesState, { payload }) => {
			state.isLoading = false;
			state.error = true;
		});
		builder.addCase(fetchSingleThread.pending, (state: IMessagesState) => {
			state.fetchThreadLoading = true;
			state.error = false;
		});
		builder.addCase(fetchSingleThread.fulfilled, (state: IMessagesState, action) => {
			const threadId = action.meta.arg.thread.chat.id;
			state.currentThread = { ...(state.currentThread as Thread), messages: action.payload };
			const index = state.threads.findIndex((thread) => thread.chat?.id === threadId);
			state.threads[index] = { ...state.threads[index], messages: action.payload };
			state.fetchThreadLoading = false;
			state.error = false;
		});
		builder.addCase(fetchSingleThread.rejected, (state: IMessagesState, { payload }) => {
			state.fetchThreadLoading = false;
			state.error = true;
		});

		builder.addCase(addThread.pending, (state: IMessagesState) => {
			state.isLoading = true;
			state.error = false;
		});
		builder.addCase(addThread.fulfilled, (state: IMessagesState, { payload }) => {
			state.threads = [...state.threads, payload];
			state.isLoading = false;
			state.error = false;
		});
		builder.addCase(addThread.rejected, (state: IMessagesState, { payload }) => {
			state.isLoading = false;
			state.error = true;
		});

		builder.addCase(blockThread.pending, (state: IMessagesState) => {
			state.isLoading = true;
			state.error = false;
		});
		builder.addCase(blockThread.fulfilled, (state: IMessagesState, { payload }) => {
			state.threads = payload;
			state.isLoading = false;
			state.error = false;
		});
		builder.addCase(blockThread.rejected, (state: IMessagesState, { payload }) => {
			state.isLoading = false;
			state.error = true;
		});

		builder.addCase(getUserDetailsWidthMessage.pending, (state: IMessagesState) => {
			state.isLoading = true;
			state.error = false;
		});
		builder.addCase(getUserDetailsWidthMessage.fulfilled, (state: IMessagesState, { payload }) => {
			// const data = state.threads.filter((thread) => thread.id === payload.id);
			// state.thread = data[0];
			state.isLoading = false;
			state.error = false;
		});
		builder.addCase(getUserDetailsWidthMessage.rejected, (state: IMessagesState, { payload }) => {
			state.isLoading = false;
			state.error = true;
		});

		builder.addCase(sendMessage.pending, (state: IMessagesState) => {
			state.isLoading = true;
			state.error = false;
		});
		builder.addCase(sendMessage.fulfilled, (state: IMessagesState, { payload }) => {
			// const newThreads = state.threads.map((thread) => {
			//   if (thread.id === payload.id) {
			//     return {
			//       ...thread,
			//       messages: [...thread.messages, payload.message],
			//     };
			//   } else {
			//     return thread;
			//   }
			// });
			//@ts-ignore
			state.threads = newThreads;
			const newSingleThread = state.threads.filter((thread) => thread.id === payload.id);
			// state.thread = newSingleThread[0];
			state.isLoading = false;
			state.error = false;
		});
		builder.addCase(sendMessage.rejected, (state: IMessagesState, { payload }) => {
			state.isLoading = false;
			state.error = true;
		});
	},
});
export const {
	setIsToast,
	setCurrentThread,
	addNewMessage,
	updateThread,
	removeChat,
	setChatHookRef,
	setUserOnlineStatus,
	updateCallsForThread,
} = messagesSlice.actions;

export default messagesSlice.reducer;
