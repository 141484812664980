import { User } from "types/stateType";

export enum PaymentStatus {
	ACTIVE = "active",
	TRIAL = "trialing",
	PAST_DUE = "past_due",
	INCOMPLETE = "incomplete",
}

export const getPaymentPlan = (user?: User): PaymentStatus => {
	return (
		user?.account?.subscription &&
		user?.account.subscription.length > 0 &&
		user?.account.subscription[user.account.subscription.length - 1].status
	);
};
