export function linkifyText(text: string): string {
    // URL regex pattern
    const urlPattern = /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    
    // Replace URLs with markdown links
    return text.replace(urlPattern, (url) => {
        // Add https:// if protocol is missing
        const fullUrl = url.startsWith('http') ? url : `https://${url}`;
        return `[${url}](${fullUrl})`;
    });
} 