import { SerializedError } from "@reduxjs/toolkit";
import { IBusinessState } from "store/reducers/businessReducer";
import { SubscriptionsPlanEnum, Tokens } from "../interfaceTypes";
import { IMessagesState } from "../store/reducers/messageReducer";
export interface IState {
	messages: IMessagesState;
	business: IBusinessState;
	subscription: SubScriptionState;
}

export type SubscriptionsPlan = {
	id?: string;
	type: SubscriptionsPlanEnum;
	currency: string;
	amount: number;
	stripeProductId: string;
	stripePriceId: string;
	subscription?: SubscriptionsEntity;
	unlimitedProjects: boolean;
	unlimitedStorage: boolean;
	bulkEditing: boolean;
	createdAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
};
export type SubscriptionsEntity = {
	id: string;
	userId: any;
	status: string;
	startDate: Date;
	endDate: Date;
	lastReneWalDate: Date;
	subscriptionPlanId: SubscriptionsPlan;
	stripeSubscriptionId: string;
	createdAt: Date;
	updatedAt: Date;
};
export type User = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	logo: string;
	countryCode: string;
	mobile: string;
	twilioNumber: any;
	subscriptionId: string;
	stripeUserId: string;
	createdAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
	twillioNumber?: any;
	isOnline?: boolean;
	isComplete?: boolean;
	account: {
		businessName: string;
		defaultAutoReplyText: string;
		referralCode: string;
		businessLogoUrl: string;
		referralLink: string;
		subscription: any;
		stripeUserId: string;
		isTrial: boolean;
		trialEnd: Date;
		voiceMail: {
			voiceMailUrl: string;
		};
	};
};
export type UserState = {
	user?: User;
	tokens?: Tokens;
	isLoggedIn: boolean;
	isLoading: boolean;
	error: boolean;
	message: string;
	isToast: boolean;
	chatHookRef: any;
};

export type SubScriptionState = {
	isLoading: boolean;
	error: boolean;
	clientSecretKey: string;
	message: string;
	subscriptions: any;
	userSubscriptionDetail: any;
	hookRef: any;
};

export const RequestInitialState = {
	success: null,
	error: null,
	inProgress: false,
};

export type Request = {
	success: boolean | null;
	inProgress: boolean | null;
	error: SerializedError | null;
};
