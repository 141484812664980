import { Dialog, Transition } from "@headlessui/react";
import RecordingUI from "components/RecordingUI";
import useAuthHook from "hooks/useAuthHook";
import { Fragment, useEffect, useState } from "react";
import API from "../../utils/axios";

export const CallModal = ({ open, onClose, clientId }: any) => {
	// const { startRecording, stopRecording } = useAudioRecorder();
	const { user } = useAuthHook();

	const [callStatus, setCallStatus] = useState("Calling"); // Initial status

	function updateStatus(status: string) {
		setCallStatus(status);
	}

	useEffect(() => {
		// Make sure the API.post call is asynchronous
		const sendCall = async () => {
			try {
				await API.post(`user/create-call/${clientId}`, { url: user?.account?.voiceMail?.voiceMailUrl });
				updateStatus("Calling");
			} catch (error) {
				console.error("Failed to send call:", error);
				updateStatus("Failed to send call");
			}
		};

		sendCall();
	}, []);
	// const handleSave = () => {
	// 	const audioBlob = stopRecording();
	// 	onClose();
	// 	API.postForm(`user/create-call/${clientId}`, { url: user?.account.voiceMail.voiceMailUrl });
	// };

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
									{callStatus}
								</Dialog.Title>
								<RecordingUI />

								<div className="mt-4">
									<button
										type="button"
										className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
										onClick={() => onClose()}
									>
										Close
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
