import { createAsyncThunk } from "@reduxjs/toolkit";
import { businessDetails } from "constant/business";
export const fetchBusinessAction = createAsyncThunk<any>(
  "fetch business",
  async (arg, thinkAPI) => {
    try {
      const response = businessDetails;
      return thinkAPI.fulfillWithValue(response);
    } catch (error: any) {
      return thinkAPI.rejectWithValue(new Error("Something is wrong here"));
    }
  }
);

export const editBusinessAction = createAsyncThunk<any>(
  "edit business",
  async (arg, thinkAPI) => {
    try {
      const response = businessDetails;
      return thinkAPI.fulfillWithValue(response);
    } catch (error: any) {
      return thinkAPI.rejectWithValue(new Error("Something is wrong here"));
    }
  }
);
