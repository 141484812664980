import { Dialog, Transition } from "@headlessui/react";
import Loading from "components/common/Loading";
import Toast from "components/common/Toast";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import { useReactMediaRecorder } from 'react-media-recorder';
import { createRecording, getRecordings, selectCreateRecordingRequest } from "store/reducers/recordingReducer";

const AddRecordingModal = ({ showRecording, closeModal }: any) => {
	const [value, setValue] = useState("custom");
	const [name, setName] = useState("");
	const [recording, setRecording] = useState<Blob | null>(null);
	const [uploadedFile, setUploadedFile] = useState<Blob | File | null>(null);
	const { inProgress } = useAppSelector(selectCreateRecordingRequest);
	const [audioDuration, setAudioDuration] = useState<number>(0);
	const {
		status,
		startRecording,
		stopRecording,
		mediaBlobUrl,
		clearBlobUrl
	} = useReactMediaRecorder({
		audio: true,
		onStop: (blobUrl, blob) => {
			setRecording(blob);
			setAudioDuration(0);
		}
	});

	useEffect(() => {
		if (mediaBlobUrl) {
			const audio = new Audio(mediaBlobUrl);
			audio.addEventListener('loadedmetadata', () => {
				if (!isNaN(audio.duration) && isFinite(audio.duration)) {
					if (audio.duration > 45) {
						console.log(audio)
						Toast.info("Recording limit is 45 seconds per recording, yours is " + audio.duration.toFixed(1) + " seconds");
						clearBlobUrl();
						setRecording(null);
					} else {
						setAudioDuration(audio.duration);
					}
				}
			});
		}
	}, [mediaBlobUrl]);

	const dispatch = useAppDispatch();

	const handleAfterUpload = (res: any) => {
		if (res.errors) {
			Toast.error(res.errors.message);
		} else {
			closeModal();
			setRecording(null);
			setName("");
			setUploadedFile(null);
			Toast.success("Success");
			dispatch(getRecordings());
		}
	};

	const handleSave = () => {
		if (value === "custom") {
			if (recording && name) {
				dispatch(createRecording({ blob: recording as Blob, name, status: "inactive" })).then(handleAfterUpload);
			} else {
				Toast.error("Please resolve errors");
			}
		} else if (!uploadedFile) {
			Toast.error("Please resolve errors");
		} else {
			dispatch(
				createRecording({ blob: uploadedFile as Blob, name: (uploadedFile as any)?.name, status: "inactive" }),
			).then(handleAfterUpload);
		}
	};

	const handleAudioChange = (e: any) => {
		setUploadedFile(e.target.files[0]);
	};

	return (
		<div className="">
			<Transition appear show={showRecording} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-green/60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
									<div className="flex gap-5 mb-6">
										<div className="flex items-center gap-2">
											<label className="text-gray-700 text-xl font-bold cursor-pointer">
												<input
													type="radio"
													id="css"
													name="fav_language"
													value="Custom"
													className="mt-0.5 mr-1"
													onClick={() => setValue("custom")}
													defaultChecked={value === "custom"}
												/>
												Record new voice greeting</label>
										</div>
										<div className="flex items-center gap-2">
											<label className="text-gray-700 text-xl font-bold cursor-pointer">
												<input
													type="radio"
													id="css"
													name="fav_language"
													value="Upload"
													className="mt-0.5 mr-1"
													onClick={() => setValue("upload")}
												/>
												Upload</label>
										</div>
									</div>
									<div className="text-center">
										<div className={`${value === "custom" ? "block" : "hidden"}`}>
											<div className="mb-6 flex flex-col items-center gap-4">
												<div className="text-lg">
													{status === 'recording' ? 'Recording...' :
														status === 'stopped' ?
															`Recording complete!` :
															'Ready to record'}
												</div>

												<div className="flex gap-4">
													{(!mediaBlobUrl && status !== 'recording') && (
														<button
															onClick={startRecording}
															className="bg-secondary text-white px-4 py-2 rounded-md hover:bg-secondary/80"
														>
															Start Recording
														</button>
													)}

													{(status === 'recording' && !mediaBlobUrl) && (
														<button
															onClick={stopRecording}
															className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
														>
															Stop Recording
														</button>
													)}

													{mediaBlobUrl && (
														<button
															onClick={() => {
																setRecording(null);
																clearBlobUrl();
															}}
															className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
														>
															Reset
														</button>
													)}
												</div>

												{mediaBlobUrl && (
													<audio controls className="w-full">
														<source src={mediaBlobUrl} />
														Your browser does not support the audio element.
													</audio>
												)}
											</div>

											<input
												className="border-light-gray border rounded-md px-3 py-2 flex justify-between items-center w-full"
												value={name}
												onChange={(e) => setName(e.target.value)}
												placeholder="Recording name"
											/>
										</div>
									</div>
									<div className="relative">
										<div
											className={` ${value === "upload" ? "block" : "hidden"
												} rounded-md border-dashed border border-light-gray p-4 flex flex-col items-center cursor-pointer mb-4`}
										>
											<MdCloudUpload className="h-10 w-10 text-light-gray" />
											<p className="text-dark-gray -mt-1.5">Upload</p>
										</div>
										<input
											type="file"
											accept="audio/*"
											onChange={handleAudioChange}
											onClick={() => setUploadedFile(null)}
											className="absolute w-full h-full z-[2] top-0 right-0 opacity-0 cursor-pointer"
										/>
									</div>
									{uploadedFile && value === "upload" && (
										<audio controls className="w-full">
											<source type={"audio/mp3"} src={URL.createObjectURL(uploadedFile as any)} />
											Your browser does not support the audio element.
										</audio>
									)}

									<div className="mt-7 flex gap-2 justify-end pt-1">
										<button
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-[#6C757D] border-2 border-[#6C757D] hover:bg-white hover:text-[#6C757D] transition duration-500"
											onClick={closeModal}
										>
											Cancel
										</button>
										<button
											onClick={handleSave}
											className="rounded-md py-1.5 px-4 text-white font-extrabold text-center bg-secondary border-2 border-secondary hover:bg-white hover:text-secondary transition duration-500"
										>
											{inProgress ? <Loading isShowing={inProgress} /> : "Save"}
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default AddRecordingModal;
