import { Transition } from "@headlessui/react";
import { FaSpinner } from "react-icons/fa";

const Loading = ({
  isShowing,
  size,
}: {
  isShowing: boolean;
  size?: number;
}): JSX.Element => {
  return (
    <>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        style={{ marginLeft: "48%" }}
      >
        <FaSpinner className="animate-spin" size={size ? size : 24} />
      </Transition>
    </>
  );
};

export default Loading;
