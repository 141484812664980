import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useSocket } from "Providers/SocketProvider";
import Toast from "components/common/Toast";
import AuthPage from "hocs/ProtectedRoute";
import { useAppDispatch } from "hooks";
import useAuthHook from "hooks/useAuthHook";
import useUserHook from "hooks/useUserHook";
import { Fragment, useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { MdArrowForward, MdBrightness5, MdCall, MdOutlineRocketLaunch } from "react-icons/md";
import { redirect } from "react-router";
import { createRecording } from "store/reducers/recordingReducer";
import API from "../../utils/axios";

interface Person {
	id: number;
	name: string;
}

const Calling = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [selected, setSelected] = useState("");
	const [query, setQuery] = useState<string>("");
	const { updateUser } = useAuthHook();
	const [defaultReply, setDefaultReply] = useState("Sorry I missed your call! How can I help?");
	const [updatingUser, setUpdatingUser] = useState(false);
	const [isCompleteRequestPending, setIsCompleteRequestPending] = useState(false);
	const { getCurrentUser } = useUserHook();
	const { user } = useAuthHook();
	const [recording, setRecording] = useState<Blob | null>(null);
	const [people] = useState<Person[]>([{ id: 1, name: "Choose another provider" }]);
	const dispatch = useAppDispatch();
	const { socket } = useSocket();
	const [callNumber, setCallNumber] = useState("");

	const recorderControls = useAudioRecorder({
		noiseSuppression: true,
		echoCancellation: true,
	});

	const { recordingTime, stopRecording } = recorderControls;

	useEffect(() => {
		if (recordingTime > 45) {
			Toast.info("Recording limit is 45 seconds per recording");
			stopRecording();
		}
	}, [recordingTime]);

	const filteredPeople =
		query === ""
			? people
			: people.filter((person) =>
					person.name.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")),
			  );

	const handleDoThisLater = async () => {
		try {
			setIsCompleteRequestPending(true);
			await API.patch("/user/is-complete");
			setIsCompleteRequestPending(false);
			await getCurrentUser();
			redirect("/");
		} catch {}
	};

	const handleSetProviders = async () => {
		try {
			// @ts-ignore
			setUpdatingUser(true);
			await updateUser({ provider: selected });
			setActiveTab(2);
			getCurrentUser();
			setUpdatingUser(false);
		} catch {
		} finally {
			setUpdatingUser(false);
		}
	};

	const handleRecordingComplete = (blob: Blob) => {
		if (blob.size > 100) {
			setRecording(blob);
		} else {
			Toast.error("Your recording length is two small");
		}
	};

	const handleSaveRecording = async () => {
		try {
			setUpdatingUser(true);
			const recordingPromise = dispatch(
				createRecording({ blob: recording as Blob, name: "onboard-recording", status: "active" }),
			);
			const updateUserPromsie = updateUser({ defaultAutoReplyText: defaultReply });
			await Promise.all([recordingPromise, updateUserPromsie]);
			setActiveTab(3);
			getCurrentUser();
		} catch {
		} finally {
			setUpdatingUser(false);
		}
	};

	socket?.on("call-received", (call: any) => {
		setCallNumber(call.client.number);
	});
	const formattedNumber = user?.twilioNumber?.[0].number
		? `${user.twilioNumber[0].number.slice(2, 5)} ${user.twilioNumber[0].number.slice(5, 9)}-${user.twilioNumber[0].number.slice(-3)}`
		: null;
	console.log("formatted Number", formattedNumber);
	return formattedNumber ? (
		<div className="flex justify-center items-center content-center mt-10">
			<div>
				<div className="flex justify-center items-center gap-10">
					<button className="bg-light-blue-200 border-2 border-blue-500 rounded-full px-2 py-2 bg-gray-200">
						<MdCall className="h-8 w-8" color="green" />
					</button>
					<MdArrowForward className="h-10 w-10" />
					<button className="bg-light-blue-200 border-2 border-blue-500 rounded-full px-2 py-2 bg-gray-200">
						<MdBrightness5 className="h-8 w-8" />
					</button>
					<MdArrowForward className="h-10 w-10" />
					<button className="bg-light-blue-200 border-2 border-blue-500 rounded-full px-2 py-2 bg-gray-200">
						<MdOutlineRocketLaunch className="h-8 w-8" color="grey" />
					</button>
				</div>

				{activeTab === 1 && formattedNumber && (
					<div className="mt-8 flex flex-col items-center">
						<div className="font-sans text-base font-medium text-slate-400 grid grid-cols-1">
							Lets get your phone connected
						</div>
						<div className="mt-8 font-sans text-xl font-medium text-slate-300 grid grid-cols-1">
							Your dedicated skipthecall phone number is {formattedNumber ? formattedNumber : "not set"}
						</div>
						<div className="mt-8 font-sans text-xl font-medium text-slate-300 grid grid-cols-1">
							First, let's forward your business phone. After a few rings your client <br />
							calls will forward to skipthecall and we'll start a new text chat with them!
						</div>
						<div className="mt-20 font-sans text-xl font-medium text-slate-300 grid grid-cols-1">
							Select your business phone provider below to get started.
						</div>
						<div className="items-center my-12 gap-2 grid grid-cols-4">
							<a
								className={`border-2 border-gray-300 text-2xl font-normal p-4 rounded-sm ${
									selected === "verizon" && "bg-zinc-300"
								}`}
								href="https://www.verizon.com/support/knowledge-base-181139/"
								target="_blank"
								onClick={() => setSelected("verizon")}
							>
								<img
									src={
										"https://s3.amazonaws.com/cms.ipressroom.com/354/files/20222/60f592e4b3aed30fb2e8a8b4_1_Brand+Logos/1_Brand+Logos_e86a51d3-11b7-42e1-b2bd-cdad912e132d-prv.jpg"
									}
								></img>
							</a>
							<a
								className={`border-2 border-gray-300 text-2xl font-normal p-4 rounded-sm ${
									selected === "sprint" && "bg-zinc-300"
								}`}
								href="https://www.ringsavvy.com/tutorials/how-to-set-up-call-forwarding-from-sprint-cell-phone/"
								target="_blank"
								onClick={() => setSelected("sprint")}
							>
								<img src={"https://upload.wikimedia.org/wikipedia/commons/6/6f/Sprint_Corporation_Logo.svg"}></img>
							</a>
							<a
								className={`border-2 border-gray-300 text-2xl font-normal p-4 rounded-sm ${
									selected === "t-mobile" && "bg-zinc-300"
								}`}
								href="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.t-mobile.com/support/tutorials/topic/calling-amp-contacts/forward-calls/device/samsung/galaxy-note-t879&ved=2ahUKEwi6p5Dyj8qFAxUzXvEDHYDeAiIQFnoECDMQAQ&usg=AOvVaw0GsNbgR3jkwoYbDybe98m0"
								target="_blank"
								onClick={() => setSelected("t-mobile")}
							>
								<img
									src={"https://www.t-mobile.com/news/_admin/uploads/2020/06/T-Mobile_New_Logo_Primary_RGB_M-on-W.jpg"}
								></img>
							</a>
							<div className="w-54">
								<Combobox value={selected} onChange={setSelected}>
									<div className="relative mt-1">
										<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
											<Combobox.Input
												className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
												displayValue={(person: Person) => person.name}
												onChange={(event) => setQuery(event.target.value)}
											/>
											<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
											</Combobox.Button>
										</div>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											afterLeave={() => setQuery("")}
										>
											<Combobox.Options
												placeholder="Choose another provider"
												className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
											>
												{filteredPeople.length === 0 && query !== "" ? (
													<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
														Nothing found.
													</div>
												) : (
													filteredPeople.map((person) => (
														<Combobox.Option
															key={person.id}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-10 pr-4 ${
																	active ? "bg-teal-600 text-white" : "text-gray-900"
																}`
															}
															value={person}
														>
															{({ selected, active }) => (
																<>
																	<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
																		{person.name}
																	</span>
																	{selected ? (
																		<span
																			className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																				active ? "text-white" : "text-teal-600"
																			}`}
																		>
																			<CheckIcon className="h-5 w-5" aria-hidden="true" />
																		</span>
																	) : null}
																</>
															)}
														</Combobox.Option>
													))
												)}
											</Combobox.Options>
										</Transition>
									</div>
								</Combobox>
							</div>
						</div>
						<div className="items-center gap-2 grid grid-cols-2">
							<button
								className={`border border-gray-400 rounded-md p-3 ${
									isCompleteRequestPending && "bg-zinc-400 cursor-not-allowed"
								}`}
								onClick={handleDoThisLater}
							>
								{isCompleteRequestPending ? "Loading..." : "I'll do this later"}
							</button>
							<button
								className={`border border-gray-400 rounded-md p-3 bg-sky-500 ${
									(!selected || updatingUser) && "bg-zinc-400 cursor-not-allowed"
								}`}
								disabled={!selected || updatingUser}
								onClick={() => {
									handleSetProviders();
								}}
							>
								{updatingUser ? "Setting providers..." : "My call forwarding is ready!"}
							</button>
						</div>
					</div>
				)}
				{activeTab === 2 && formattedNumber && (
					<div className="mt-8 flex flex-col items-center">
						<div className="font-sans text-base font-medium text-slate-400">Now just a few account settings</div>
						<div className="grid grid-cols-2 mt-20 justify-center items-center gap-4">
							<div className="font-medium text-slate-300">Set your initial text message for new calls</div>
							<textarea
								className="font-lg text-slate-300 border-2 border-gray-400 rounded-md p-2 w-[350px] h-[200px]"
								value={defaultReply}
								onChange={(e) => setDefaultReply(e.target.value)}
							></textarea>
						</div>

						<div style={{ width: "100%" }} className="font-sans flex  mt-8 justify-center items-center gap-4">
							<div style={{ width: "30%" }} className="font-medium text-slate-300">
								Writer's block? Here are a few suggestions, just click one to use it!
							</div>
							<div className="flex flex-col gap-2">
								<div
									className="font-lg text-slate-300 border-2 border-gray-400 rounded-md p-2 cursor-pointer"
									onClick={() =>
										setDefaultReply(
											"I'm on the other line with another customer, but shoot me a text and I'll get right back to you.",
										)
									}
								>
									I'm on the other line with another customer, but shoot me a text and I'll get right back to you.
								</div>
								<div
									className="font-lg text-slate-300 border-2 border-gray-400 rounded-md p-2 cursor-pointer"
									onClick={() => setDefaultReply("Sorry we missed you! How can I or my teammates help?")}
								>
									Sorry we missed you! How can I or my teammates help?
								</div>
							</div>
						</div>

						<div className="font-sans font-medium text-slate-200 grid grid-cols-2 mt-20 justify-center items-center gap-4">
							<div className="flex flex-col gap-2 justify-center items-center">
								<div>Record your voice greeting!</div>
								<div className="mt-4">
									(this is what clients will hear when their
									<br /> call is forwarded to skipthecall)
								</div>
							</div>
							<div className="flex justify-start items-center">
								<span>
									<AudioRecorder
										onRecordingComplete={handleRecordingComplete}
										recorderControls={recorderControls}
										downloadFileExtension="mp3"
										showVisualizer={true}
									/>
								</span>
								{recording && (
									<audio controls className="w-full">
										<source src={URL.createObjectURL(recording)} />
										Your browser does not support the audio element.
									</audio>
								)}
								{/* <span>
									<MdOutlinePlayCircleFilled className="h-14 w-14" color="grey" />
								</span> */}
							</div>
						</div>

						<div className="gap-2 mt-14 grid grid-cols-2 justify-center items-center">
							<button className="border-2 border-gray-400 rounded-md p-2" onClick={handleDoThisLater}>
								I'll do this later
							</button>
							<button
								className={`border-2 border-gray-400 rounded-md bg-sky-200 p-2 ${
									(!recording || updatingUser) && "bg-zinc-400 cursor-not-allowed"
								}`}
								onClick={handleSaveRecording}
							>
								{updatingUser ? "Updating data..." : "Let's Go!"}
							</button>
						</div>
					</div>
				)}
				{activeTab === 3 && formattedNumber && (
					<div className="mt-8 flex flex-col items-center">
						<div className="text-sm">All set! Let's try it out.</div>
						<div className="mt-20 mb-10 flex justify-center items-center gap-4">
							<div>
								Call your business line from now from <br />
								another phone to see it in action!
							</div>
							<div>
								<MdCall className="h-9 w-9" color="grey" />
							</div>
							<div style={{ fontSize: "24px" }}>{user?.twilioNumber?.number}</div>
						</div>

						{callNumber && (
							<div className="grid grid-rows-2 mt-10 mb-14 justify-center items-center">
								<div className="text-lg font-normal">Call recieved from {callNumber}</div>

								<button
									className="border-2 border-gray-400 rounded-md p-2 bg-sky-200"
									onClick={() => {
										handleDoThisLater();
									}}
								>
									Let's go to the inbox!
								</button>
							</div>
						)}

						<div className="mt-20">
							<button className="border-2 border-gray-400 rounded-md p-2" onClick={handleDoThisLater}>
								I'll do this later
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	) : (
		<div className="flex flex-col justify-center items-center h-screen">
			{" "}
			<p className="text-center p-16">
				Sorry, you can't set your call forwarding at the moment. Please wait until you are assigned a Twilio Number
			</p>
			<div className="mt-20">
				<button className="border-2 border-gray-400 rounded-md p-2" onClick={handleDoThisLater}>
					Take me to the app
				</button>
			</div>
		</div>
	);
};

export default AuthPage(Calling);
