import { useEffect } from 'react';

const SetViewportHeight = () => {
    useEffect(() => {
        const setVh = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVh(); // Initial call to set the correct value on page load

        window.addEventListener('resize', setVh);
        window.addEventListener('orientationchange', setVh);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('resize', setVh);
            window.removeEventListener('orientationchange', setVh);
        };
    }, []);

    return null; // This component does not render anything
};

export default SetViewportHeight;
