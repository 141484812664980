import { ChatMethodsProvider } from "Providers/ChatMethodsProvider";
import { SocketProvider } from "Providers/SocketProvider";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import store from "store";
import AppRouter from "./routes";
// @ts-ignore
import MessageRingSound from "./assets/sounds/message-ringtone.wav";
// @ts-ignore
import CallRingSound from "./assets/sounds/call-ringtone.wav";

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<ToastContainer
					position="bottom-center"
					autoClose={4000}
					hideProgressBar={true}
					newestOnTop={true}
					closeOnClick
					rtl={false}
					draggable={false}
					theme="colored"
					pauseOnHover={false}
					style={{ width: "auto", color: "darkred" }}
					transition={Slide}
				/>
				<SocketProvider>
					<ChatMethodsProvider>
						<AppRouter />
					</ChatMethodsProvider>
				</SocketProvider>
				<audio src={MessageRingSound} id="message-ring-audio" />
				<audio src={CallRingSound} id="call-ring-audio" />
			</BrowserRouter>
		</Provider>
	);
}
export default App;
