import React from "react";
import Sidebar from "./sidebar";

type LayoutProps = {
	children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
	return (
		<div className="flex">
			{/* <div className="bg-yellow-200 text-yellow-800 p-4 fixed top-0 left-0 right-0 z-50">
				Your product is in 30 days trial mode
			</div> */}
			<Sidebar />
			{children}
		</div>
	);
};

export default Layout;
