import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "../../components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constant";
import API, { APISuccessResponse } from "../../utils/axios";

export const CreateUserSubscriptionAction = createAsyncThunk<APISuccessResponse, { priceId: string }>(
	"subscriptions/create",
	async (arg, thinkAPI) => {
		try {
			const { data, status } = await API.post("subscriptions/create", arg);
			if (status !== 201) {
				throw Error(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return thinkAPI.fulfillWithValue(data.data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return thinkAPI.rejectWithValue(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
		}
	},
);

export const clearClientSecret = createAction("subscription/clearClientSecret");
