import ReminderModal from "components/ReminderModal";
import Toast from "components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdCheck, MdCheckBoxOutlineBlank, MdClose, MdOutlineSportsMartialArts } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { chatResolution } from "store/actions/messageAction";
import { setCurrentThread, updateThread } from "store/reducers/messageReducer";
import { IState } from "types/stateType";
import { convertUTCToLocaleString, get9amNDaysFromNow } from "utils/getTimeUntil9AM";
import API from "../../utils/axios";
import { Note } from "./Note";

interface IContactInfo {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ChatNotes: React.FC<IContactInfo> = ({ isOpen, setIsOpen }) => {
	const { currentThread } = useSelector((state: IState) => state.messages);
	const [show, setShow] = useState(false);
	const [notes, setNotes] = useState<{ id: string; text: string }[]>([]);
	const [newNote, setNewNote] = useState("");
	const [showNewNote, setShowNewNote] = useState(false);

	const dispatch = useDispatch();

	const isDone = currentThread?.chat?.chatResolution?.[0]?.type === "done";
	const slug = currentThread?.chat?.chatResolution?.[0]?.slug;
	const isSpam = currentThread?.chat?.chatResolution?.[0]?.type === "spam";
	const isBlocked = currentThread?.blocked?.length && currentThread?.blocked?.length > 0 ? true : false;
	const resolution = slug
		? slug
			.split("_")
			.map((item) => `${item.slice(0, 1).toUpperCase()}${item.substring(1)}`)
			.join(" ")
		: null;
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1500 && currentThread) {
				setIsOpen(true); // Keep the div open by default
			} else if (window.innerWidth < 1200) {
				setIsOpen(false);
			}
		};

		handleResize(); // Check on component mount
		window.addEventListener("resize", handleResize); // Add event listener for resizing

		return () => {
			window.removeEventListener("resize", handleResize); // Cleanup on component unmount
		};
	}, [currentThread?.id]);

	useEffect(() => {
		setNotes((currentThread?.clientNote as any) || []);
	}, [currentThread]);

	const handleChatResolution = (slug: string, type: string, status: string) => {
		if (!currentThread?.chat?.id) return;

		dispatch(chatResolution({
			slug,
			chatId: currentThread.chat.id,
			type,
			status
		}) as any).then((res: any) => {
			if (res.error?.message) {
				Toast.error(SOMETHING_WENT_WRONG_MESSAGE);
				return;
			}
			Toast.success("Success");
			setIsOpen(false);

			const updatedThread = {
				...currentThread,
				chat: {
					...currentThread?.chat,
					chatResolution:
						status === "inactive"
							? null
							: { slug: res.payload.slug, type, id: res.payload.id, status: res.payload.status },
				},
			};

			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
		});
	};

	const deleteSnooze = () => {
		API.delete(`/snoozeReminders/${currentThread?.chat?.snoozeReminder[0].id}`).then((res) => {
			const updatedThread = { ...currentThread, chat: { ...currentThread?.chat, snoozeReminder: null } };
			Toast.success("Snooze removed, moving chat back to Active!");
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
			setIsOpen(false);
		});
	};

	const snoozeRemainder = (customDate: string | null, days?: number) => {
		let date: Date | string = new Date();
		if (customDate) {
			date = customDate;
		} else if (days) {
			date = get9amNDaysFromNow(days);
		}

		console.log({ date });
		API.post("/snoozeReminders", { status: "true", snoozeUntil: date, chatId: currentThread?.chat?.id }).then((res) => {
			console.log({ date });
			const updatedThread = {
				...currentThread,
				chat: {
					...currentThread?.chat,
					snoozeReminder: { id: res.data.data.id, snoozeUntil: new Date(date).toISOString() },
				},
			};
			console.log({ updatedThread });
			Toast.success("Snooze added for the chat");
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
			setIsOpen(false);
		});
	};

	function closeModal() {
		setShow(false);
	}

	function openModal() {
		setShow(true);
	}

	const addNote = () => {
		API.post("/clientNotes", { text: newNote, clientId: currentThread?.id }).then((res) => {
			const updatedThread = {
				...currentThread,
				clientNote: [...(currentThread?.clientNote as any[]), { id: res.data.data.id, text: newNote }],
			};
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
			setShowNewNote(false);
			setNewNote("");
		});
	};

	return (
		<div
			className={`${isOpen ? "translate-x-0" : "translate-x-full"
				} z-[99] fixed right-0 sm:w-[380px] w-[320px] h-screen shadow-1xl bg-white transition duration-500`}
		>
			<div className="flex justify-end items-center border-b border-light-gray px-6 py-[26px] ">
				<button onClick={() => setIsOpen(false)}>
					<MdClose className="h-6 w-6 text-slate" />
				</button>
			</div>
			{ }
			{!isBlocked ? (
				<div className="p-4 h-[calc(100%-77px)] overflow-y-auto">
					<div className="mb-10">
						<div className="flex justify-between  mb-2 flex-col">
							<label className="text-dark-gray text-sm font-semibold w-full">NOTES</label>
							<div>
								{currentThread?.clientNote?.map((note) => (
									<Note note={note} />
								))}
							</div>
							{!showNewNote && (
								<div
									onClick={() => setShowNewNote(true)}
									role="button"
									className="flex justify-center items-center m-1 p-1 bg-gray-200 rounded-full border-2 border-gray-500 hover:shadow-lg hover:bg-white cursor-pointer"
								>
									<span className="flex items-center space-x-2">
										<FaPlus />
										<span>Add a new note</span>
									</span>
								</div>
							)}
							{showNewNote && (
								<>
									<textarea
										placeholder="Add note here"
										value={newNote}
										onChange={(e) => setNewNote(e.target.value)}
										className="p-2.5 mt-4 placeholder:text-light-gray/60 text-primary border border-light-gray rounded-lg outline-none focus:border-black w-full"
									/>

									<button
										onClick={addNote}
										disabled={!newNote.length}
										className={`bg-[#28a745] px-2.5 rounded-2xl text-sm text-white font-bold py-0.5 mt-2`}
									>
										Save
									</button>
								</>
							)}
						</div>
					</div>
					{(isDone || isSpam) && (
						<button
							className="w-full bg-secondary rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6 hover:bg-green hover:shadow-lg"
							onClick={() => handleChatResolution("moved_back_to_active", "move", "inactive")}
						>
							<div className="w-6 h-6 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#ea6974] to-[#dc3545]">
								<MdOutlineSportsMartialArts className="h-3.5 w-3.5" />
							</div>
							<p className="text-white text-sm font-semibold">Move back to Active</p>
						</button>
					)}

					{!isSpam && (
						<button
							className="w-full bg-secondary rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6  hover:bg-green hover:shadow-lg"
							onClick={() => {
								const reason = "appointment_scheduled";
								if (slug && slug === reason) {
									Toast.info("Chat Resolution already set !");
									return;
								}
								handleChatResolution(reason, "done", "active");
							}}
						>
							<div className="w-4 h-4 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#28a745] to-[#0f7a26]">
								{slug && slug === "appointment_scheduled" ? (
									<MdCheck className="h-3.5 w-3.5" />
								) : (
									<MdCheckBoxOutlineBlank className="h-3.5 w-3.5" />
								)}
							</div>
							<p className="text-white text-sm font-semibold">Done ( appointment scheduled )</p>
						</button>
					)}

					{!isSpam && (
						<button
							className="w-full bg-secondary rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6  hover:bg-green hover:shadow-lg"
							onClick={() => {
								const reason = "question_answered";
								if (slug && slug === reason) {
									Toast.info("Chat Resolution already set !");
									return;
								}
								handleChatResolution(reason, "done", "active");
							}}
						>
							<div className="w-4 h-4 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#28a745] to-[#0f7a26]">
								{slug && slug === "question_answered" ? (
									<MdCheck className="h-3.5 w-3.5" />
								) : (
									<MdCheckBoxOutlineBlank className="h-3.5 w-3.5" />
								)}
							</div>
							<p className="text-white text-sm font-semibold">Done ( question answered )</p>
						</button>
					)}
					{!isSpam && (
						<button
							className="w-full bg-secondary rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6  hover:bg-green hover:shadow-lg"
							onClick={() => {
								const reason = "not_booked";
								if (slug && slug === reason) {
									Toast.info("Chat Resolution already set !");
									return;
								}
								handleChatResolution(reason, "done", "active");
							}}
						>
							<div className="w-4 h-4 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#28a745] to-[#0f7a26]">
								{slug && slug === "not_booked" ? (
									<MdCheck className="h-3.5 w-3.5" />
								) : (
									<MdCheckBoxOutlineBlank className="h-3.5 w-3.5" />
								)}
							</div>
							<p className="text-white text-sm font-semibold">Done ( didn’t book client )</p>
						</button>
					)}
					{!isSpam && (
						<button
							className="w-full bg-[#FF8F75] rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6 hover:shadow-lg  hover:bg-green"
							onClick={() => {
								const reason = "spam";
								if (slug && slug === reason) {
									Toast.info("Chat Resolution already set !");
									return;
								}
								handleChatResolution(reason, "spam", "active");
							}}
						>
							<div className="w-4 h-4 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#bb4f58] to-[#dc3545]">
								<MdClose className="h-3.5 w-3.5" />
							</div>
							<p className="text-white text-sm font-semibold">Delete ( junk or spam )</p>
						</button>
					)}
					{resolution && (
						<div className="text-center ">
							<h1 className="text-grey-900 text-lg pb-2">{resolution}</h1>
						</div>
					)}
					{/*
					 {!isSpam && (
						<button
							className="w-full bg-[#FF8F75] rounded-3xl px-4 py-2.5 flex items-center gap-2 mb-6"
							onClick={() => {
								const reason = "spam";
								if (slug && slug === reason) {
									Toast.info("Chat Resolution already set !");
									return;
								}
								chatResolution("spam", "spam");
							}}
						>
							<div className="w-4 h-4 rounded text-white flex items-center justify-center shadow-1xl bg-gradient-to-b from-[#ea6974] to-[#dc3545]">
								<MdClose className="h-3.5 w-3.5" />
							</div>
							<p className="text-white text-sm font-semibold">Spam</p>
						</button>
					)} */}
					{currentThread?.chat?.snoozeReminder?.[0] &&
						currentThread.chat.snoozeReminder[0].snoozeUntil > new Date().toISOString() ? (
						<div>
							<p className="text-[13px] font-semibold">
								Snoozed until {convertUTCToLocaleString(currentThread.chat?.snoozeReminder[0].snoozeUntil)}{" "}
							</p>
							<button onClick={deleteSnooze}>Remove</button>
						</div>
					) : (
						<div className="flex flex-wrap gap-1.5">
							<button
								className="bg-[#225363] rounded-3xl px-2 py-2.5 flex items-center gap-2"
								onClick={() => snoozeRemainder(null, 1)}
							>
								<p className="text-white text-[13px] font-semibold">⏳ Remind me in 1 day </p>
							</button>
							<button
								className="bg-[#225363] rounded-3xl px-2 py-2.5 flex items-center gap-2"
								onClick={() => snoozeRemainder(null, 7)}
							>
								<p className="text-white text-[13px] font-semibold">⏳ Remind me in 1 week </p>
							</button>
							<button onClick={openModal} className="bg-[#225363] rounded-3xl px-2 py-2.5 flex items-center gap-2">
								<p className="text-white text-[13px] font-semibold">⏳ Remind me on.. </p>
							</button>
							<ReminderModal show={show} closeModal={closeModal} onSave={(date: string) => snoozeRemainder(date)} />
						</div>
					)}
				</div>
			) : (
				<div className="flex justify-center items-center h-full">
					<p className="text-dark-black text-lg font-bold w-full text-center p-4">
						You can not set notes or resolutions for this chat!
					</p>
				</div>
			)}
		</div>
	);
};

export default ChatNotes;
