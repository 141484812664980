import { SliceCaseReducers, createSlice } from "@reduxjs/toolkit";
import localStorage from "../../services/localStorage.service";
import { UserState } from "../../types/stateType";
import {
	ForgotPasswordAction,
	LoginAction,
	ResetPasswordAction,
	SignUpAction,
	fetchUserAction,
	logoutAction,
} from "../actions/userAction";

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, string>({
	name: "user",
	initialState: {
		user: { ...(localStorage.getItem("user") ?? {}) },
		tokens: localStorage.getItem("tokens"),
		isLoggedIn: localStorage.getItem("tokens") ? true : false,
		isLoading: false,
		error: false,
		message: "",
		isToast: false,
		chatHookRef: null,
	},
	reducers: {
		setIsToast(state, { payload }: { payload: { isToast: boolean; message?: string } }) {
			state.isToast = payload.isToast;
		},
	},
	extraReducers: (builder) => {
		/* *********** signUp Action *********** */
		builder.addCase(SignUpAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(SignUpAction.fulfilled, (state, { payload }: any) => {
			state.tokens = payload.tokens;
			// state.user = payload.account;
			state.error = false;
			state.isLoading = false;
			state.isToast = true;
			state.isLoggedIn = true;
			if (payload) {
				state.message = payload.message;
			}
		});
		builder.addCase(SignUpAction.rejected, (state, { payload }: any) => {
			state.isLoading = false;
			state.error = true;
			state.tokens = undefined;
			state.isToast = true;
			if (payload) {
				state.message = payload.message;
			}
		});

		/* ***********  Login Action *********** */
		builder.addCase(LoginAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(LoginAction.fulfilled, (state, { payload }: any) => {
			state.tokens = payload.tokens;
			// state.user = payload.account;
			state.isLoading = false;
			state.isToast = true;
			state.error = false;
			state.isToast = true;
			state.isLoggedIn = true;

			if (payload) {
				state.message = payload.message;
			}
			window.location.href = '/'
		});
		builder.addCase(LoginAction.rejected, (state, { payload }: any) => {
			state.isLoading = false;
			state.error = true;
			state.tokens = undefined;
			state.isLoggedIn = false;
			state.isToast = true;
			if (payload) {
				state.message = payload.message;
			}
		});

		/* ***********  ForgotPassword Action *********** */
		builder.addCase(ForgotPasswordAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(ForgotPasswordAction.fulfilled, (state, { payload }) => {
			state.error = false;
			state.isLoading = false;
			if (payload) {
				state.message = payload.message;
			}
		});
		builder.addCase(ForgotPasswordAction.rejected, (state, { payload }: any) => {
			state.error = true;
			state.isLoading = false;
			if (payload) {
				state.message = payload?.message;
			}
		});

		/* ***********  ResetPassword Action *********** */
		builder.addCase(ResetPasswordAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(ResetPasswordAction.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.isToast = true;
			state.error = false;
			if (payload) {
				state.message = payload.message;
			}
		});
		builder.addCase(ResetPasswordAction.rejected, (state, { payload }: any) => {
			state.isLoading = false;
			state.isToast = true;
			state.error = true;
			if (payload) {
				state.message = payload.message;
			}
		});

		// fetchUserAction
		builder.addCase(fetchUserAction.pending, (state) => {
			state.isLoading = true;
			state.error = false;
		});
		builder.addCase(fetchUserAction.fulfilled, (state, { payload }) => {
			console.log({ payload });
			state.user = payload;
			state.isLoading = false;
			state.error = false;
		});
		builder.addCase(fetchUserAction.rejected, (state, { payload }: any) => {
			state.isLoading = false;
			// state.user = undefined;
			// state.tokens = undefined;
			if (payload) state.message = payload.message;
		});

		// userLogout
		builder.addCase(logoutAction.fulfilled, (state) => {
			state.user = undefined;
			state.tokens = undefined;
			state.isLoggedIn = false;
			window.location.href = "/login";
		});
	},
});

export const { setIsToast } = userSlice.actions;
export default userSlice.reducer;
