import { SliceCaseReducers, createSlice } from "@reduxjs/toolkit";

import {
  CreateUserSubscriptionAction,
  clearClientSecret,
} from "../actions/subScriptionAction";
import { SubScriptionState } from "../../types/stateType";

export const subScriptionSlice = createSlice<
  SubScriptionState,
  SliceCaseReducers<SubScriptionState>
>({
  name: "subscription",
  initialState: {
    subscriptions: [],
    userSubscriptionDetail: [],
    clientSecretKey: "",
    error: false,
    isLoading: false,
    message: "",
    hookRef: null,
  },
  reducers: {
    setHookRef: (state, action) => {
      state.hookRef = action.payload
    },
    updateClientSecret: (state, action) => {
      state.clientSecretKey = action.payload
    }
  },
  extraReducers(builder) {
    /* *********** CreateSubscription Action *********** */
    builder.addCase(CreateUserSubscriptionAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      CreateUserSubscriptionAction.fulfilled,
      (state, { payload }: any) => {
        state.isLoading = false;
        state.error = false;
        if (payload) {
          state.message = payload.message;
          state.clientSecretKey = payload.clientSecret;
        }
      }
    );
    builder.addCase(
      CreateUserSubscriptionAction.rejected,
      (state, { payload }: any) => {
        state.isLoading = false;
        state.error = true;
        if (payload) {
          state.message = payload.message;
        }
      }
    );

    builder.addCase(clearClientSecret, (state) => {
      state.clientSecretKey = "";
    });
  },
});

export const { setHookRef, updateClientSecret } = subScriptionSlice.actions;


export default subScriptionSlice.reducer;
