import { useDispatch } from "react-redux";
import { fetchUserAction } from "../store/actions/userAction";

const useUserHook = () => {
	const dispatch = useDispatch();

	const getCurrentUser = () => {
		return dispatch<any>(fetchUserAction());
	};

	return {
		getCurrentUser,
	};
};

export default useUserHook;
