import { DoneChats, OldCalls, TotalMessages } from "./template";

const Charts = () => {
	return (
		<div className="flex flex-col items-center justify-center ">
			<div className="max-w-screen-xl mx-auto px-4">
				<div className="flex flex-wrap justify-center gap-4">
					<div className="w-2/4 sm:w-1/2 lg:w-1/3">
						<OldCalls />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Charts;
