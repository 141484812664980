import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as Lifesaver } from 'assets/icons/lifesaver.svg';
import { ReactComponent as Spyglass2 } from 'assets/icons/spyglass-2.svg';
import { ReactComponent as SteeringWheel } from 'assets/icons/steering-wheel.svg';
import { ReactComponent as TreasureMap } from 'assets/icons/treasure-map.svg';
import Logo from "assets/images/logo.png";
import MobileLogo from "assets/images/mobile-logo.png";

import { Fragment } from "react";
import { CiBadgeDollar } from "react-icons/ci";
import { MdFace5, MdLock, MdLogout } from "react-icons/md";

import useAuthHook from "hooks/useAuthHook";
import { Link } from "react-router-dom";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";

const settingsOptions = [
	{ href: "/settings/profile", icon: <MdFace5 className="w-5 h-5 mr-2" />, title: "Profile" },
	{ href: "/settings/subscription", icon: <CiBadgeDollar className="w-5 h-5 mr-2" />, title: "Subscription" },
	{ href: "/settings/security", icon: <MdLock className="w-5 h-5 mr-2" />, title: "Update password" },
];

// Add this CSS class to handle tooltips (you can also put this in your CSS file)
const tooltipStyles = "after:content-[attr(data-tooltip)] after:absolute after:left-[120%] after:top-1/2 after:-translate-y-1/2 after:bg-gray-800 after:text-white after:px-2 after:py-1 after:rounded after:text-sm after:whitespace-nowrap after:opacity-0 hover:after:opacity-100 after:transition-opacity relative";

const Sidebar = () => {
	const { logout, user } = useAuthHook();

	const plan = getPaymentPlan(user);
	const isTrial = plan === PaymentStatus.TRIAL;
	const handleLogout = async () => {
		await logout();
	};
	const refreshSession = async () => {

	}

	return (
		<div className="xlg:w-[75px] w-screen py-3.5 xlg:px-2.5 px-4 flex xlg:flex-col justify-between items-center xlg:h-full h-14 bg-blue-dianne fixed xlg:z-0 z-20">
			<div>
				<Link to="/" className="text-lg text-white font-medium">
					<img src={Logo} alt="Logo" className="xlg:block hidden xlg:h-10 xlg:w-10 h-9 w-9" />

					<img src={MobileLogo} alt="Logo" className="xlg:hidden block xsm:w-[170px] w-[140px]" />
				</Link>
			</div>

			<div className="flex xlg:flex-col xsm:gap-5 gap-2 items-center justify-center">
				<Link to="/" className={tooltipStyles} data-tooltip="Conversations">
					<TreasureMap className="xsm:h-8 xsm:w-8 h-6 w-6 text-white fill-white" />
				</Link>
				<Link to="/#search" className={tooltipStyles} data-tooltip="Search">
					<Spyglass2 className="xsm:h-8 xsm:w-8 h-6 w-6 text-white fill-white" />
				</Link>
				<Menu>
					{({ open }) => (
						<div className="relative xsm:h-7 h-5">
							<Menu.Button className={`${!open ? tooltipStyles : 'relative'}`} data-tooltip="Settings">
								<SteeringWheel className="xsm:h-10 xsm:w-10 h-5 w-5 text-white fill-white pb-2" />
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute xlg:left-[51px] xlg:-bottom-[66px] w-36 xlg:origin-bottom-left origin-top xlg:right-[initial] right-0 xlg:top-[initial] xsm:top-[42px] top-[38px] xlg:rounded-tr-md xlg:rounded-b-none rounded-b-md bg-[#144B5C]">
									<div className="py-1.5">
										{settingsOptions?.map((link) => (
											<Menu.Item key={link.title}>
												{({ active }: any) => (
													<Link
														to={link?.href}
														className={`${active ? "bg-white/10" : ""
															} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													>
														{link?.icon}
														{link?.title}
													</Link>
												)}
											</Menu.Item>
										))}
										{/* <Menu.Item>
											{({ active }: any) => (
												<Link
													to={"#"}
													className={`${active ? "bg-white/10" : ""
														} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													onClick={refreshSession}
												>
													<DrinkIcon className="fill-white w-5 h-5 mr-2" />
													Refresh session
												</Link>
											)}
										</Menu.Item> */}
										<Menu.Item>
											{({ active }: any) => (
												<Link
													to={"#"}
													className={`${active ? "bg-white/10" : ""
														} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													onClick={handleLogout}
												>
													<MdLogout className="w-5 h-5 mr-2" />
													Logout
												</Link>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</div>
					)}
				</Menu>
				<Link
					to="mailto:hello@skipthecall.com?subject=skipthecall%20help"
					target="_blank"
					className={tooltipStyles + " fill-white"}
					data-tooltip="Get Help"
				>
					<Lifesaver className="xsm:h-8 xsm:w-8 h-6 w-6 text-white" />
				</Link>
				<Link to={"/settings/profile"} className={tooltipStyles} data-tooltip="Profile">
					<img
						src={user?.account?.businessLogoUrl || `https://placehold.co/150x150?text=${user?.account.businessName
							.split(' ')
							.map(word => word.charAt(0).toUpperCase())
							.join('')
							}`}
						alt="business-logo"
						className="border-2 border-white rounded-full xsm:h-11 xsm:w-11 h-8 w-8"
					/>
				</Link>
			</div>
		</div>
	);
};
export default Sidebar;