// @ts-ignore
import { Disclosure, Transition } from "@headlessui/react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronUp, FaMagnifyingGlass } from "react-icons/fa6";
import { MdCheck, MdClose, MdFavorite, MdHourglassTop, MdMarkEmailUnread } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { chatResolution, fetchThreadsAction } from "store/actions/messageAction";
import { Thread, setCurrentThread, updateThread } from "store/reducers/messageReducer";
import "swiper/css";
import { IState } from "types/stateType";
import API from "../../utils/axios";

import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import Toast from "components/common/Toast";
import { Link } from "react-router-dom";
import { get9amNDaysFromNow } from "utils/getTimeUntil9AM";
import { DoneChats, OldCalls, TotalMessages } from "../charts/template";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			beginAtZero: true,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
	},
};

interface IMessageList {
	open: boolean;
	setOpen: (open: boolean) => void;
}
const styles = {
	statContainer: {
		display: 'flex',
		flexDirection: 'row' as 'row',
		justifyContent: 'space-around' as 'space-around',
		alignItems: 'center' as 'center',
		paddingBottom: '20px'
	},
};
const MessageList: React.FC<IMessageList> = ({ setOpen }) => {
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const { threads, noThreads, fetchAllClientsLoading, currentThread } = useSelector((state: IState) => state.messages);
	const [search, setSearch] = useState("");
	const [filteredThreads, setFilteredThreads] = useState<Thread[]>([]);
	const [statsMessages, setStatsMessages] = useState<any>();
	const [statsCalls, setStatsCalls] = useState<any>();
	const [statsDoneChats, setStatsDoneChats] = useState<any>();
	const searchInputRef = useRef<HTMLInputElement>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const { numberOrId } = useParams();

	let a = 0;
	const dispatchFetchThreads = useCallback(() => {
		if (a === 0) {
			dispatch(fetchThreadsAction());
			a++;
		}
	}, []);

	useEffect(() => {
		if (noThreads === null) {
			dispatchFetchThreads();
		}
	}, []);

	const getUserMessages = async (id: string, thread: any) => {
		if (!thread.chat) {
			console.log("No thread chat found!");
			// const res = await API.post(`/chats/${thread.id}`);
			// const updatedThread = {
			// 	...thread,
			// 	chat: res.data.data,
			// };
			// dispatch(updateThread(updatedThread));
			// dispatch(setCurrentThread(updatedThread));
		} else {
			dispatch(setCurrentThread(thread));
		}
		setOpen(true);
	};

	const handleSearchChange = (e: any) => {
		setSearch(e.target.value);
		const filteredThreads = threads?.filter((thread) =>
			thread.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()),
		);
		setFilteredThreads(filteredThreads);
	};

	useEffect(() => {
		if (currentThread) {
			let title = "Skip the call | ";
			title += currentThread?.name || currentThread?.number;
			document.title = title;
			navigate(`/chat/${currentThread?.number || currentThread?.id}`, { replace: true });
		}
	}, [currentThread?.name, currentThread?.number, currentThread?.id, navigate]);

	useEffect(() => {
		if (numberOrId && threads) {
			const matchedThread = threads.find(
				thread => thread.number === numberOrId || thread.id === numberOrId
			);
			if (matchedThread) {
				getUserMessages(matchedThread.id, matchedThread);
			}
		}
	}, [numberOrId, threads]);

	useEffect(() => {
		if (location.hash === '#search' && searchInputRef.current) {
			searchInputRef.current.focus();
			const parentDiv = searchInputRef.current.closest('div');
			if (parentDiv) {
				parentDiv.style.transition = 'box-shadow 0s';
				parentDiv.style.boxShadow = '0 0 10px 2px #225363';
				setTimeout(() => {
					parentDiv.style.transition = 'box-shadow 2s';
					parentDiv.style.boxShadow = '';
				}, 100);
			}
		}
	}, [location]);

	const activeConversations: Thread[] = [];
	const doneConversations: Thread[] = [];
	const blockedConversations: Thread[] = [];
	const snoozedThreads: Thread[] = [];

	threads?.forEach((thread) => {
		// Check active first
		if (thread?.chat?.status === "Active" &&
			(!thread?.chat?.chatResolution?.length || thread?.chat?.chatResolution?.[0]?.type === "move")) {
			activeConversations.push(thread);
		}
		// Then done
		else if (thread?.chat?.chatResolution?.[0]?.type === "done") {
			doneConversations.push(thread);
		}
		// Then blocked/spam
		else if (thread?.chat?.chatResolution?.[0]?.type === "spam" || thread?.blocked?.length > 0) {
			blockedConversations.push(thread);
		}
		// Finally snoozed
		else if (new Date(thread?.chat?.snoozeReminder?.[0]?.snoozeUntil) > new Date() &&
			thread?.chat?.chatResolution?.[0]?.type !== "move") {
			snoozedThreads.push(thread);
		}
	});

	const handleChatResolution = (thread: Thread, slug: string, type: string, status: string) => {
		dispatch(chatResolution({
			slug,
			chatId: thread.chat?.id,
			type,
			status,
		})).then((res: any) => {
			console.log(`Updated thread: ${thread.name} with status ${type}`);
			if (res.error?.message) {
				Toast.error("Error resolving chat");
				return;
			}
			// Toast.success("Success");

			const updatedThread = {
				...thread,
				chat: {
					...thread?.chat,
					chatResolution: [{
						slug: res.payload.data.slug,
						type,
						id: res.payload.data.id,
						status: res.payload.data.status
					}],
				},
			};
			console.log("Chat resolution response: ", res.payload);

			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
		});
	};

	const snooze = (thread: Thread, days: number) => {
		let date: Date | string = new Date();
		date = get9amNDaysFromNow(days);

		API.post("/snoozeReminders", { status: "true", snoozeUntil: date, chatId: thread?.chat?.id }).then((res) => {
			const updatedThread = {
				...currentThread,
				chat: {
					...currentThread?.chat,
					snoozeReminder: { id: res.data.data.id, snoozeUntil: new Date(date).toISOString() },
				},
			};
			Toast.success("Snooze added for the chat");
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
		});
	};

	// const renderThread = (thread: Thread) => (
	// 	<div className={`${thread.id === currentThread?.id ? "bg-gray-200" : "bg-inherit"}`}>
	// 		<div className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full">
	// 			<button
	// 				onClick={() => getUserMessages(thread?.id, thread)}
	// 				className="flex-grow text-left"
	// 			>
	// 				{/* Existing thread content */}
	// 			</button>
	// 			<button
	// 				onClick={(e) => {
	// 					e.stopPropagation();
	// 					handleChatResolution(thread);
	// 				}}
	// 				className="p-2 rounded-full hover:bg-green-100"
	// 			>
	// 				<MdCheck className="h-5 w-5 text-green-600" />
	// 			</button>
	// 		</div>
	// 	</div>
	// );

	return (
		<div className={` xlg:w-[400px] w-full fixed xlg:left-[75px] bg-white xlg:top-0 top-14 h-screen xlg:z-[-1] z-10`}>
			<div className="relative border border-light-gray rounded mx-5 my-5">
				<input
					ref={searchInputRef}
					type="text"
					placeholder="Search.."
					className="outline-none pl-4 placeholder:text-[#a8a8a8a] text-primary py-[11px] rounded w-full"
					onChange={handleSearchChange}
					id="search"
				/>
				<FaMagnifyingGlass className="w-5 h-5 text-[#A8A8A8] absolute right-4 top-1/2 translate-y-[-50%]" />
			</div>
			{fetchAllClientsLoading ? (
				<div>
					<div className="p-4 flex flex-col gap-3">
						<div className=" bg-gray-200 h-4 rounded w-90"></div>
						<div className=" bg-gray-200 h-4 rounded w-60"></div>
					</div>
					<div className="p-4 flex flex-col gap-3">
						<div className=" bg-gray-200 h-4 rounded w-90"></div>
						<div className=" bg-gray-200 h-4 rounded w-60"></div>
					</div>
					<div className="p-4 flex flex-col gap-3">
						<div className=" bg-gray-200 h-4 rounded w-90"></div>
						<div className=" bg-gray-200 h-4 rounded w-60"></div>
					</div>
				</div>
			) : (
				<div className="h-custom-height overflow-scroll">
					{search ? (
						filteredThreads?.map((thread: Thread, i: number) => {
							return (
								<div className="mr-0.5">
									<button
										onClick={() => getUserMessages(thread?.id, thread)}
										className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full"
									>
										{/* <div className="relative">
                              <img
                                src={thread?.userSender?.avatar}
                                alt="profile"
                                className="h-10 w-10 rounded-full"
                              />
                            </div> */}
										<div className="w-full">
											<div className="flex justify-between items-center mb-0.5">
												<div className="flex items-center gap-1">
													{thread?.isOnline && <span className="bg-[#52b752] w-2 h-2 rounded "></span>}
													<p className="text-[16px] text-[#434343] font-semibold">{thread?.name}</p>
												</div>
												{thread?.favorite && <MdFavorite className="w-4 h-4 text-cyan-800" />}
											</div>
											<div className="flex justify-between items-center">
												<p className="text-sm text-[#9C9C9C] font-medium w-[calc(100%-20px)] text-left">
													{thread?.chat?.lastMessage?.length > 150 ? thread?.chat?.lastMessage?.slice(0, 150) + '...' : thread?.chat?.lastMessage}
												</p>
												{thread?.chat?.unreadMsgs !== undefined && thread?.chat?.unreadMsgs !== 0 && (
													<div className="h-5 w-5 rounded-full bg-secondary text-[10px] font-bold flex items-center justify-center text-white">
														{thread?.chat?.unreadMsgs}
													</div>
												)}
											</div>
										</div>
									</button>
								</div>
							);
						})
					) : (
						<>
							<Link to="/charts"></Link>
							{/*<div className="flex justify-around items-center p-2">*/}
							<div style={styles.statContainer}>
								<button
									onClick={() => {
										console.log("Clicked calls history!");
									}}
								>
									<OldCalls />
								</button>
								<button
									onClick={() => {
										console.log("Clicked message history!");
									}}
								>
									<TotalMessages />
								</button>
								<button
									onClick={() => {
										console.log("Clicked message history!");
									}}
								>
									<DoneChats />
								</button>
							</div>
							<Disclosure as="div" className="mb-1" defaultOpen>
								{(panel: { open: boolean; close: any }) => {
									const { open, close } = panel;

									return (
										<>
											<Disclosure.Button
												className="flex w-full justify-between bg-[#459A91] px-4 py-2.5 text-left text-sm font-bold text-white"
											// disabled={open}
											// onClick={() => {
											// 	// if (!open) {
											// 	close();
											// 	// }

											// 	togglePanels({ ...panel, key: 0 });
											// }}
											>
												<span>Active Conversations ({activeConversations?.length || 0})</span>
												<FaChevronUp
													className={`${open ? "rotate-180 transform" : ""
														} h-4 w-4 text-white  transition duration-500`}
												/>
											</Disclosure.Button>
											<Transition
												className="overflow-hidden"
												enter="transition-all ease-in-out duration-700 delay-200"
												enterFrom="transform  max-h-0"
												enterTo="transform  max-h-[1000px]"
												leave="transition-all ease-in-out duration-500"
												leaveFrom="transform  max-h-[1000px]"
												leaveTo="transform  max-h-0"
											>
												<Disclosure.Panel
													as="div"
													className="xlg:h-[calc(100vh-134px-224px)] h-[calc(100vh-134px-224px-56px)] overflow-auto mr-0.5 hello"
												>
													{activeConversations?.length > 0 ? (
														activeConversations?.map((thread: Thread, i: number) => {
															return (
																<div className={`relative ${thread.id === currentThread?.id ? "bg-gray-200" : "bg-inherit"}`}>
																	<div className="absolute right-1 top-1 flex gap-1">
																		<div className="group relative">
																			<button
																				onClick={() => handleChatResolution(thread, "appointment_scheduled", "done", "active")}
																				className="p-1 bg-[#008950] rounded hover:bg-[#00663c]"
																			>
																				<MdCheck className="h-4 w-4 text-white" />
																			</button>
																			<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																				Mark done (appointment scheduled)
																			</span>
																		</div>
																		<div className="group relative">
																			<button
																				onClick={() => snooze(thread, 7)}
																				className="p-1 bg-yellow-600 rounded hover:bg-yellow-700"
																			>
																				<MdHourglassTop className="h-4 w-4 text-white" />
																			</button>
																			<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																				Snooze for 1 week
																			</span>
																		</div>
																		<div className="group relative">
																			<button
																				onClick={() => handleChatResolution(thread, "spam", "spam", "active")}
																				className="p-1 bg-red-700 rounded hover:bg-red-800"
																			>
																				<MdClose className="h-4 w-4 text-white" />
																			</button>
																			<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																				Delete (junk or spam)
																			</span>
																		</div>

																	</div>
																	<button
																		onClick={() => getUserMessages(thread?.id, thread)}
																		className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full"
																	>
																		<div className="w-full">
																			<div className="flex justify-between items-center mb-0.5">
																				<div className="flex items-center gap-1">
																					{thread?.isOnline && <span className="bg-[#52b752] w-2 h-2 rounded "></span>}
																					<p className="text-[16px] text-[#434343] font-semibold">{thread?.name}</p>
																				</div>
																				{thread.favorite && <MdFavorite className="w-4 h-4 text-cyan-800" />}
																			</div>
																			<div className="flex justify-between items-center">
																				<p className="text-sm text-[#9C9C9C] font-medium w-[calc(100%-20px)] text-left">
																					{thread?.chat?.lastMessage?.length > 150 ? thread?.chat?.lastMessage?.slice(0, 150) + '...' : thread?.chat?.lastMessage}
																				</p>
																				{thread?.chat?.unreadMsgs !== undefined && thread?.chat?.unreadMsgs !== 0 && (
																					<div className="h-5 w-5 rounded-full bg-secondary text-[10px] font-bold flex items-center justify-center text-white">
																						{thread?.chat?.unreadMsgs}
																					</div>
																				)}
																			</div>
																		</div>
																	</button>
																</div>
															);
														})
													) : (
														<div className="flex justify-center items-center h-full">
															<p className="text-center text-gray-500">
																No active conversations. Let's get someone to call you! Or do it yourself!
															</p>
														</div>
													)}
												</Disclosure.Panel>
											</Transition>
										</>
									);
								}}
							</Disclosure>
							<Disclosure as="div" className="mb-1">
								{(panel: { open: boolean; close: any }) => {
									const { open, close } = panel;
									// if (activeDisclosurePanel.key === 2) {
									// 	firstDisclosureCloseRef = panel;
									// }
									return (
										<>
											<Disclosure.Button
												className="flex w-full justify-between bg-yellow-600 px-4 py-2.5 text-left text-sm font-bold text-white"
											// disabled={open}
											// onClick={() => {
											// 	// if (!open) {
											// 	close();
											// 	// }

											// 	togglePanels({ ...panel, key: 2 });
											// }}
											>
												<span>Snoozed Conversations ({snoozedThreads?.length || 0})</span>
												<FaChevronUp
													className={`${open ? "rotate-180 transform" : ""
														} h-4 w-4 text-white  transition duration-500`}
												/>
											</Disclosure.Button>
											<Transition
												className="overflow-hidden"
												enter="transition-all ease-in-out duration-700 delay-200"
												enterFrom="transform  max-h-0"
												enterTo="transform  max-h-[1000px]"
												leave="transition-all ease-in-out duration-500"
												leaveFrom="transform  max-h-[1000px]"
												leaveTo="transform  max-h-0"
											>
												<Disclosure.Panel
													as="div"
													className="xlg:h-[calc(100vh-134px-224px)] h-[calc(100vh-134px-224px-56px)] overflow-auto"
												>
													{snoozedThreads?.map((thread: Thread, i: number) => {
														return (
															<div className={`relative ${thread.id === currentThread?.id ? "bg-gray-200" : "bg-inherit"}`}>
																<div className="absolute right-1 top-1 flex gap-1">
																	<div className="group relative">
																		<button
																			onClick={() => handleChatResolution(thread, "moved_back_to_active", "move", "active")}
																			className="p-1 bg-[#008950] rounded hover:bg-[#00663c]"
																		>
																			<MdMarkEmailUnread className="h-4 w-4 text-white" />
																		</button>
																		<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																			Move back to active
																		</span>
																	</div>
																</div>
																<button
																	onClick={() => getUserMessages(thread?.id, thread)}
																	className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full"
																>
																	<div className="relative">
																		{/* <img
                                src={thread?.userSender?.avatar}
                                alt="profile"
                                className="h-10 w-10 rounded-full"
                              /> */}
																	</div>
																	<div className="w-[calc(100%-40px)]">
																		<div className="flex justify-between items-center mb-0.5">
																			<div className="flex items-center gap-1">
																				{thread?.isOnline && <span className="bg-[#52b752] w-2 h-2 rounded "></span>}
																				<p className="text-[16px] text-[#434343] font-semibold">{thread?.name}</p>
																			</div>
																			<p className="text-xs text-[#505050] font-medium">
																				{/* {moment(
                                    thread?.messages[
                                      thread?.messages.length - 1
                                    ]?.createdAt
                                  ).format("h:mm a")} */}
																			</p>
																		</div>
																		<div className="flex justify-between items-center">
																			<p className="text-sm text-[#9C9C9C] font-medium w-[calc(100%-20px)] text-left">
																				{thread?.chat?.lastMessage?.length > 150 ? thread?.chat?.lastMessage?.slice(0, 150) + '...' : thread?.chat?.lastMessage}
																			</p>
																			{thread?.chat?.unreadMsgs !== undefined &&
																				thread?.chat?.unreadMsgs !== null &&
																				thread?.chat?.unreadMsgs !== 0 &&
																				typeof thread?.chat?.unreadMsgs !== "undefined" && (
																					<div className="h-5 w-5 rounded-full bg-secondary text-[10px] font-bold flex items-center justify-center text-white">
																						{thread?.chat?.unreadMsgs}
																					</div>
																				)}
																		</div>
																	</div>
																</button>
															</div>
														);
													})}
												</Disclosure.Panel>
											</Transition>
										</>
									);
								}}
							</Disclosure>
							<Disclosure as="div" className="mb-1">
								{(panel) => {
									const { open, close } = panel;
									return (
										<>
											<Disclosure.Button
												className="flex w-full justify-between bg-[#008450] px-4 py-2.5 text-left text-sm font-bold text-white"
											// disabled={open}
											// onClick={() => {
											// 	if (!open) {
											// 		close();
											// 	}

											// 	togglePanels({ ...panel, key: 3 });
											// }}
											>
												<span>Done ({doneConversations.length || 0}) </span>
												<FaChevronUp
													className={`${open ? "rotate-180 transform" : ""} h-4 w-4 text-white transition duration-500`}
												/>
											</Disclosure.Button>
											<Transition
												className="overflow-hidden"
												enter="transition-all ease-in-out duration-700 delay-200"
												enterFrom="transform  max-h-0"
												enterTo="transform  max-h-[1000px]"
												leave="transition-all ease-in-out duration-500"
												leaveFrom="transform  max-h-[1000px]"
												leaveTo="transform  max-h-0"
											>
												<Disclosure.Panel
													as="div"
													className="xlg:h-[calc(100vh-134px-224px)] h-[calc(100vh-134px-224px-56px)] overflow-auto mr-0.5"
												>
													{doneConversations?.map((thread: Thread, i: number) => {
														return (
															<div className={`relative ${thread.id === currentThread?.id ? "bg-gray-200" : "bg-inherit"}`}>
																<div className="absolute right-1 top-1 flex gap-1">
																	<div className="group relative">
																		<button
																			onClick={() => handleChatResolution(thread, "moved_back_to_active", "move", "active")}
																			className="p-1 bg-[#008950] rounded hover:bg-[#00663c]"
																		>
																			<MdMarkEmailUnread className="h-4 w-4 text-white" />
																		</button>
																		<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																			Move back to active
																		</span>
																	</div>
																</div>
																<button
																	onClick={() => getUserMessages(thread?.id, thread)}
																	className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full"
																>
																	{/* <div className="relative">
                              <img
                                src={thread?.userSender?.avatar}
                                alt="profile"
                                className="h-10 w-10 rounded-full"
                              />
                            </div> */}
																	<div className="w-full">
																		<div className="flex justify-between items-center mb-0.5">
																			<div className="flex items-center gap-1">
																				{thread?.isOnline && <span className="bg-[#52b752] w-2 h-2 rounded "></span>}
																				<p className="text-[16px] text-[#434343] font-semibold">{thread?.name}</p>
																			</div>

																			<p className="text-xs text-[#505050] font-medium">
																				{/* {moment(
                                    thread?.chat?.createdAt
                                  ).format("h:mm a")} */}
																			</p>
																		</div>
																		<div className="flex justify-between items-center">
																			<p className="text-sm text-[#9C9C9C] font-medium w-[calc(100%-20px)] text-left">
																				{thread?.chat?.lastMessage?.length > 150 ? thread?.chat?.lastMessage?.slice(0, 150) + '...' : thread?.chat?.lastMessage}
																			</p>
																			{thread?.chat?.unreadMsgs !== undefined && thread?.chat?.unreadMsgs != 0 && (
																				<div className="h-5 w-5 rounded-full bg-secondary text-[10px] font-bold flex items-center justify-center text-white">
																					{thread?.chat?.unreadMsgs}
																				</div>
																			)}
																		</div>
																	</div>
																</button>
															</div>
														);
													})}
												</Disclosure.Panel>
											</Transition>
										</>
									);
								}}
							</Disclosure>
							<Disclosure as="div" className="mb-1">
								{(panel) => {
									const { open, close } = panel;
									return (
										<>
											<Disclosure.Button
												className="flex w-full justify-between bg-red-800 px-4 py-2.5 text-left text-sm font-bold text-white"
											// disabled={open}
											// onClick={() => {
											// 	if (!open) {
											// 		close();
											// 	}

											// 	togglePanels({ ...panel, key: 4 });
											// }}
											>
												<span>Blocked / Spam ({blockedConversations.length || 0})</span>
												<FaChevronUp
													className={`${open ? "rotate-180 transform" : ""} h-4 w-4 text-white transition duration-500`}
												/>
											</Disclosure.Button>
											<Transition
												className="overflow-hidden"
												enter="transition-all ease-in-out duration-700 delay-200"
												enterFrom="transform  max-h-0"
												enterTo="transform  max-h-[1000px]"
												leave="transition-all ease-in-out duration-500"
												leaveFrom="transform  max-h-[1000px]"
												leaveTo="transform  max-h-0"
											>
												<Disclosure.Panel
													as="div"
													className="xlg:h-[calc(100vh-134px-224px)] h-[calc(100vh-134px-224px-56px)] overflow-auto mr-0.5"
												>
													{blockedConversations?.map((thread: Thread, i: number) => {
														return (
															<div className="mr-0.5">
																<div className="absolute right-1 top-1 flex gap-1">
																	<div className="group relative">
																		<button
																			onClick={() => handleChatResolution(thread, "moved_back_to_active", "move", "active")}
																			className="p-1 bg-[#008950] rounded hover:bg-[#00663c]"
																		>
																			<MdMarkEmailUnread className="h-4 w-4 text-white" />
																		</button>
																		<span className="invisible group-hover:visible absolute -bottom-8 right-0 whitespace-nowrap bg-gray-800 text-white text-xs rounded px-2 py-1">
																			Move back to active
																		</span>
																	</div>
																</div>
																<button
																	onClick={() => getUserMessages(thread?.id, thread)}
																	className="flex justify-between items-center py-4 xsm:px-6 px-4 gap-2.5 border-b border-[#C5C5C5] hover:bg-black/[0.04] transition duration-500 w-full"
																>
																	<div className="w-full">
																		<div className="flex justify-between items-center mb-0.5">
																			<p className="text-sm text-[#434343] font-semibold">{thread?.name}</p>
																			<p className="text-xs text-[#505050] font-medium">
																				{/* {moment(
                                    thread?.chat?.createdAt
                                  ).format("h:mm a")} */}
																			</p>
																		</div>
																		<div className="flex justify-between items-center">
																			<p className="text-sm text-[#9C9C9C] font-medium w-[calc(100%-20px)] text-left">
																				{thread?.chat?.lastMessage?.length > 150 ? thread?.chat?.lastMessage?.slice(0, 150) + '...' : thread?.chat?.lastMessage}
																			</p>
																			{thread?.chat?.unreadMsgs !== undefined && thread?.chat?.unreadMsgs != 0 && (
																				<div className="h-5 w-5 rounded-full bg-secondary text-[10px] font-bold flex items-center justify-center text-white">
																					{thread?.chat?.unreadMsgs}
																				</div>
																			)}
																		</div>
																	</div>
																</button>
															</div>
														);
													})}
												</Disclosure.Panel>
											</Transition>
										</>
									);
								}}
							</Disclosure>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default MessageList;
